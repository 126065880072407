import {
  addMonths,
  addWeeks,
  addDays,
  format,
  isEqual,
  isSameMonth,
  isToday,
  parse,
  getDay,
  subDays,
  eachDayOfInterval,
  getDaysInMonth,
  setDate,
  subMonths,
} from 'date-fns'
import React, { useEffect, useState } from 'react'
import { AptiveIcon } from '@aptive-env/storybook'
import { CustomButton } from '../Button'
interface ICalendarCell {
  text: string;
  value: Date;
}

function changeDateMonth(date: Date, isNextMonth: boolean) {
  return addMonths(date, isNextMonth ? 1 : -1)
}

function changeDateWeek(date: Date, isNextMonth: boolean): Date {
  return addWeeks(date, isNextMonth ? 1 : -1)
}

function getActiveRow(date: Date): Array<ICalendarCell> {
  const unShiftAmount = getDay(date)
  const unPushAmount = 7 - getDay(date) - 1

  const startDay = subDays(date, unShiftAmount)
  const endDay = addDays(date, unPushAmount)

  const thisWeek = eachDayOfInterval(
    { start: startDay, end: endDay },
  )

  const rows: ICalendarCell[] = thisWeek.map((day: Date) => {
    return { text: String(getDay(day)), value: day }
  })

  return rows
}

function getCalendarCells(date: Date): ICalendarCell[] {
  const daysArray = new Array(getDaysInMonth(date)).fill(1)
  const calendarCells: ICalendarCell[] = []
  const prepareCell = (date: Date, dayNumber: number) => {
    return {
      text: String(dayNumber),
      value: setDate(date, dayNumber),
    }
  }

  daysArray.forEach((_, i) => {
    calendarCells.push(prepareCell(date, i + 1))
  })

  const unShiftAmount = getDay(calendarCells[0]?.value)
  const unPushAmount = 7 - getDay(calendarCells[calendarCells.length - 1]?.value) - 1

  const lastMonth = subMonths(date, 1)
  for (let i = 0; i < unShiftAmount; i++) {
    calendarCells.unshift(prepareCell(lastMonth, getDaysInMonth(lastMonth) - i))
  }

  const nextMonth = addMonths(date, 1)
  for (let i = 0; i < unPushAmount; i++) {
    calendarCells.push(prepareCell(nextMonth, i + 1))
  }
  return calendarCells
}

function getCalendarRows(date: Date): Array<ICalendarCell[]> {
  const cells = getCalendarCells(date)
  const rows: Array<ICalendarCell[]> = []

  for (let i = 0; i < cells.length; i += 7) {
    rows.push(cells.slice(i, i + 7))
  }

  return rows
}

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ')
}

export type MiniCalendarProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  view: 'week' | 'month',
  currentDate: Date,
  onChange: (newDate: Date) => void;
  handleCalendarIsOpen: (value: boolean) => void;
}

const MiniCalendar = React.forwardRef<HTMLDivElement, MiniCalendarProps>(function (params, ref) {
  const { className, view, currentDate, onChange, handleCalendarIsOpen, ...props } = params

  const [shownDate, setShownDate] = useState(currentDate || new Date())
  const [currentMonth, setCurrentMonth] = useState(format(shownDate, 'MMM-yyyy'))
  const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
  const [rows, setRows] = useState<ICalendarCell[][]>()
  const [weekRow, setWeekRow] = useState<ICalendarCell[]>()
  const [selectedDay, setSelectedDay] = useState<Date>(currentDate || new Date())

  const handleIconClick = (isNext: boolean) => {
    if (view === 'month') {
      setShownDate(changeDateMonth(shownDate, isNext))
    } else if (view === 'week') {
      setShownDate(changeDateWeek(shownDate, isNext))
    }
  }

  const handleSelectDay = (date: Date) => {
    onChange(date)
  }

  const renderDay = (value: Date) => {
    return (
      <button
        type="button"
        onClick={() => setSelectedDay(value)}
        className={classNames(
          isEqual(value, selectedDay) && 'bg-[#007AFF] text-white',
          !selectedDay && isToday(value) && 'bg-[#007AFF] text-white',
    
          !isEqual(value, selectedDay) && !selectedDay && isToday(value) && 'text-brand-core-pine',
          !isEqual(value, selectedDay) && isSameMonth(value, firstDayCurrentMonth) && 'text-gray-900',
          !isEqual(value, selectedDay) && !isSameMonth(value, firstDayCurrentMonth) && 'text-gray-400',
          isEqual(value, currentDate) && !isEqual(value, selectedDay) && 'text-gray-900',
    
          (isEqual(value, currentDate) || isToday(value)) && 'font-semibold',
          view === 'week' ? 'rounded-full' : 'rounded-lg',
          'text-paragraph mx-auto flex h-full w-full items-center justify-center',

          !isEqual(value, selectedDay) && 'hover:bg-gray-200'
        )}
      >
        <time dateTime={format(value, 'yyyy-MM-dd')}>
          {format(value, 'd')}
        </time>
      </button>
    )
  }

  useEffect(() => {
    if (view === 'month') {
      setRows(getCalendarRows(shownDate))
    } else if (view === 'week') {
      setWeekRow(getActiveRow(shownDate))
    }
    setCurrentMonth(format(shownDate, 'MMM-yyyy'))
  }, [shownDate, view])

  return (
    <div
      ref={ref}
      className={className}
      {...props}
    >
      <div className={`flex justify-between items-center ${view === 'month' ? 'mb-2' : ''}`}>
        <button
          type="button"
          aria-label="prev-btn"
          onClick={() => handleIconClick(false)}
          className={`flex items-center justify-center ${view === 'week' ? 'w-[44px] h-[59px]' : 'w-[20px] h-[20px]'}`}
        >
          <span className="sr-only">Previous month</span>
          <AptiveIcon
            className={`fill-gray-400 hover:fill-gray-900 ${view === 'week' ? 'h-[24px] w-[24px]' : 'h-[16px] w-[16px]'}`}
            icon={view === 'week' ? 'chevronLeft' : 'arrowLeft'}
            isFilled
          />
        </button>
        <div className={`px-4 ${view === 'week' ? 'text-sm font-medium text-text-default' : 'text-xs font-semibold text-gray-900'}`}>
          {format(shownDate, 'MMMM yyyy')}
        </div>
        <button
          type="button"
          aria-label="next-btn"
          onClick={() => handleIconClick(true)}
          className={`flex items-center justify-center ${view === 'week' ? 'w-[44px] h-[59px]' : 'w-[20px] h-[20px]'}`}
        >
          <span className="sr-only">Next month</span>
          <AptiveIcon
            className={`fill-gray-400 hover:fill-gray-900 ${view === 'week' ? 'h-[24px] w-[24px]' : 'h-[16px] w-[16px]'}`}
            icon={view === 'week' ? 'chevronRight' : 'arrowRight'}
            isFilled
          />
        </button>
      </div>

      <div
        className={`flex justify-center text-center font-semibold ${view === 'week' ? 'text-[13px] text-gray-600 mb-[3px] gap-3' : 'text-[12px] text-text-default'}`}
      >
        {view === 'month' ?
          rows && rows[0].map(({text, value}, index) =>
            <div key={index} className="w-10 h-8 flex items-center justify-center">{format(value, 'eeee').slice(0, 3)}</div>
          )
          : view === 'week' &&
          weekRow && weekRow.map(({ text, value }, index) =>
            <div key={index} className="w-10 items-center uppercase">{format(value, 'eeee').slice(0, 3)}</div>
          )
        }
      </div>

      {view === 'week' && weekRow &&
        <div className="flex justify-center gap-3 text-sm mb-1" key="week">
          {weekRow.map(({ text, value }, i) => (
            <div
              key={`${text} - ${i}`}
              className={classNames('flex items-center my-1 w-[40px] h-[40px]')}
            >
              {renderDay(value)}
            </div>
          ))}
        </div>
      }

      {view === 'month' && rows?.map((cells, rowIndex) => (
        <div className="flex justify-center text-sm mb-1" key={rowIndex}>
          {cells.map(({ text, value }, i) => (
            <div
              key={`${text} - ${i}`}
              className={classNames('w-10 h-8 flex items-center justify-center')}
            >
              {renderDay(value)}
            </div>
          ))}
        </div>
      ))}
      <div className='flex gap-2'>
        <CustomButton
          text="Ok"
          onClick={() => handleSelectDay(selectedDay)}
          className="w-full bg-blue-500 text-white items-center"
        />
        <CustomButton
          text="Cancel"
          color="white"
          onClick={() => handleCalendarIsOpen(false)}
          className="w-full"
        />
      </div>
    </div>
  )
})

export default MiniCalendar
