import { FC, useState } from 'react'

import LicenseApplicationTable from './LicenseApplicationTable'
import LicenseApplicationModal from './LicenseApplication'
import ChecklistSelectModal from './ChecklistSelectModal'
import { Application, ApplicationDetails, Attachment, Checklist } from 'interface'
import { useToast } from 'hooks/useToast'
import { fetchApplication, fetchApplicationAttachments, fetchChecklistAttachments } from 'services'

const LicenseApplicationsView: FC = () => {
  const { setToast } = useToast()
  const [isChecklistSelectModalOpen, setIsChecklistSelectModalOpen] = useState(false)
  const [isLicenseApplicationModalOpen, setIsLicenseApplicationModalOpen] = useState(false)
  const [selectedApplicationDetails, setSelectedApplicationDetails] = useState<Partial<ApplicationDetails> | null>(null)
  const [selectedApplicationAttachments, setSelectedApplicationAttachments] = useState<Attachment[]>([])
  const [selectedChecklistAttachments, setSelectedChecklistAttachments] = useState<Attachment[]>([])
  const [listDataVersion, setListDataVersion] = useState<number>(0)

  const handleSelectChecklist = async (checklist: Checklist) => {
    const checklistAttachmentsResult = await fetchChecklistAttachments(checklist.id)
    setSelectedApplicationDetails({
      checklist_id: checklist.id,
      state: checklist.state,
      county: checklist.county,
      municipality: checklist.municipality,
      requirements: checklist.requirements,
      submission_type: checklist.submission_type,
      submission_email: checklist.submission_email,
      submission_url: checklist.submission_url,
      priority: checklist.priority,
    })
    setSelectedApplicationAttachments([])
    setSelectedChecklistAttachments(checklistAttachmentsResult.result.attachments)
    setIsChecklistSelectModalOpen(false)
    setIsLicenseApplicationModalOpen(true)
  }

  const handleCloseApplicationModal = () => {
    setSelectedApplicationDetails(null)
    setSelectedApplicationAttachments([])
    setIsLicenseApplicationModalOpen(false)
  }

  const handleClickNewApplication = () => {
    setIsChecklistSelectModalOpen(true)
  }

  const handleClickEditApplication = async (application: Application) => {
    const applicationResponse = await fetchApplication(application.id)
    const attachmentsResponse = await fetchApplicationAttachments(application.id)
    const checklistAttachmentsResult = await fetchChecklistAttachments(applicationResponse.result.checklist_id)
    setSelectedApplicationDetails(applicationResponse.result)
    setSelectedApplicationAttachments(attachmentsResponse.result.attachments)
    setSelectedChecklistAttachments(checklistAttachmentsResult.result.attachments)
    setIsLicenseApplicationModalOpen(true)
  }

  const handleSubmitApplicationModal = ({ success, message }: { success: boolean, message: string }) => {
    setIsLicenseApplicationModalOpen(false)
    setSelectedApplicationDetails(null)
    setSelectedApplicationAttachments([])
    setToast({
      type: success ? 'success' : 'danger',
      message,
    })
    setListDataVersion((prev) => prev + 1) // refresh the data list
  }

  return (
    <div className="flex flex-col h-0 grow overflow-hidden p-3">
      <LicenseApplicationTable
        version={listDataVersion}
        onClickNewApplication={handleClickNewApplication}
        onClickEditApplication={handleClickEditApplication}
      />
      <ChecklistSelectModal
        isOpen={isChecklistSelectModalOpen}
        onClose={() => setIsChecklistSelectModalOpen(false)}
        onSelectChecklist={handleSelectChecklist}
      />
      {selectedApplicationDetails && (
        <LicenseApplicationModal
          isOpen={isLicenseApplicationModalOpen}
          details={selectedApplicationDetails}
          attachments={selectedApplicationAttachments}
          checklistAttachments={selectedChecklistAttachments}
          onClose={handleCloseApplicationModal}
          onSubmit={handleSubmitApplicationModal}
        />
      )}
    </div>
  )
}

export default LicenseApplicationsView
