import { County } from 'interface'
import { apiClient } from './config'

export const fetchCounties = async (params?: {
  'state_abbr': string
}) : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    counties: County[]
  }
}> => {
  const filteredParams = Object.fromEntries(
    Object.entries(params || {})
      .filter(([_, value]) => value !== undefined)  // filter out undefined values
      .map(([key, value]) => [key, value.toString()]) // convert values to strings
  )

  const queryString = new URLSearchParams(filteredParams).toString()
  const response = await apiClient(`counties?${queryString}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}
