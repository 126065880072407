import { useRef, useEffect } from 'react'

export const useDraggable = (isLoading: boolean) => {
  const elementRef: React.RefObject<HTMLDivElement | undefined> = useRef(null)
  const isDragging = useRef(false)
  const startX = useRef(0)
  const scrollLeft = useRef(0)
  const startY = useRef(0)
  const scrollTop = useRef(0)

  const handleMouseDown = (event: MouseEvent) => {
    if (elementRef.current) {
      isDragging.current = true
      startX.current = event.pageX - elementRef.current.offsetLeft
      startY.current = event.pageY - elementRef.current.offsetTop
      scrollLeft.current = elementRef.current.scrollLeft
      scrollTop.current = elementRef.current.scrollTop
    }
  }

  const handleMouseLeave = () => {
    isDragging.current = false
  }

  const handleMouseUp = (event: MouseEvent) => {
    isDragging.current = false
  }

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging.current || !elementRef.current) {
      return
    }

    const x = event.pageX - elementRef.current.offsetLeft
    const y = event.pageY - elementRef.current.offsetTop
    const walkX = (x - startX.current) * 2
    const walkY = (y - startY.current) * 2
    elementRef.current.scrollLeft = scrollLeft.current - walkX
    elementRef.current.scrollTop = scrollTop.current - walkY
  }

  const handleClick = (event: MouseEvent) => {
    if (elementRef.current) {
      if (
        Math.abs(event.pageX - startX.current - elementRef.current.offsetLeft) > 5 ||
        Math.abs(event.pageY - startY.current - elementRef.current.offsetTop) > 5
      ) {
        event.stopPropagation()
      }
    }
  }

  useEffect(() => {
    if (!isLoading && elementRef.current) {
      const currentElementRef = elementRef.current
      currentElementRef.addEventListener('mousedown', handleMouseDown)
      currentElementRef.addEventListener('mouseleave', handleMouseLeave)
      currentElementRef.addEventListener('click', handleClick)
      currentElementRef.addEventListener('mouseup', handleMouseUp)
      currentElementRef.addEventListener('mousemove', handleMouseMove)

      return () => {
        if (currentElementRef) {
          currentElementRef.removeEventListener('mousedown', handleMouseDown)
          currentElementRef.removeEventListener('mouseleave', handleMouseLeave)
          currentElementRef.removeEventListener('click', handleClick)
          currentElementRef.removeEventListener('mouseup', handleMouseUp)
          currentElementRef.removeEventListener('mousemove', handleMouseMove)
        }
      }
    }
  }, [isLoading])

  return { elementRef }
}
