import { FC, useState, useMemo, useEffect } from 'react'
import { Popover } from 'components/common'
import MiniCalendar from 'components/common/Calendar/MiniCalendar'
import classNames from 'classnames'
import { formatDateDisplay } from 'lib/utils'
import { AptiveIcon } from '@aptive-env/storybook'
import moment from 'moment'

interface ICustomDateProps {
  disabled?: boolean
  formValue?: string
  value?: string
  hasError?: boolean
  id: string
  name: string
  type: string
  className?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  baseClasses?: {
    errorClasses: string
    standardClasses: string
  }
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  required?: boolean
  register?: any
  watch?: any
  dropdownPosition?: 'left' | 'right'
}

const CustomDate: FC<ICustomDateProps> = ({
  id,
  name,
  formValue,
  value,
  hasError,
  className,
  onChange,
  baseClasses,
  required,
  disabled,
  register,
  watch,
  dropdownPosition = 'right',
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<any>(formValue ? moment(formValue) : moment())
  const [isDateSelected, setIsDateSelected] = useState(false)
  const classes = useMemo(() => classNames(
    className,
    'shadow-sm block w-full sm:text-sm rounded-md rounded-r-none',
    `${hasError && baseClasses ? baseClasses?.errorClasses : baseClasses?.standardClasses}`,
    { 'text-gray-400': disabled },
  ), [hasError, className, baseClasses, disabled])

  const calendarIconClasses = useMemo(() => classNames(
    'flex gap-2 bg-gray-50 rounded-md shadow-sm border-l-0 rounded-l-none px-4 w-20 h-[38px] border border-gray-300',
    { 'border-red-500 bg-pink-100': hasError }
  ), [hasError])

  const handleChangeSelectedDate = (date: Date) => {
    setSelectedDate(moment(date))
    setIsDateSelected(true)
    setIsCalendarOpen(false)
    if (onChange) {
      onChange({
        target: {
          name,
          value: date.toISOString(),
        },
      } as React.ChangeEvent<HTMLInputElement>)
    }
  }

  const handleClearDate = () => {
    setIsDateSelected(false)
    setIsCalendarOpen(false)
    if (onChange) {
      onChange({
        target: {
          name,
          value: '',
        },
      } as React.ChangeEvent<HTMLInputElement>)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const watchedValue = watch(name)
      if (watchedValue) {
        setSelectedDate(moment(watchedValue))
        setIsDateSelected(true)
      }
    }, 0)
    
    return () => clearTimeout(timer)
  }, [watch, name])  

  return (
    <Popover
      open={isCalendarOpen}
      content={
        <div
          className="bg-white p-[10px] rounded-lg z-100"
          style={{
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
          }}
        >
          <MiniCalendar
            view="month"
            currentDate={selectedDate?.toDate()}
            onChange={handleChangeSelectedDate}
            handleCalendarIsOpen={setIsCalendarOpen}
          />
        </div>
      }
      position={dropdownPosition}
      onClickOutside={() => setIsCalendarOpen(false)}
    >
      <div className="flex flex-row flex-grow">
        <div
          className={classes}
          onClick={disabled ? () => {} : () => setIsCalendarOpen((prev) => !prev)}
        >
          {isDateSelected ? formatDateDisplay(selectedDate) : <span className='text-gray-400'>Select Date</span>}
        </div>
        <div className={calendarIconClasses}>
          <AptiveIcon
            className="w-5 h-5 fill-[#9CA3AF] stroke-none m-auto hover-gray-700 cursor-pointer"
            icon="x"
            isFilled
            onClick={handleClearDate}
          />
          <AptiveIcon
            className="w-5 h-5 fill-none stroke-[#9CA3AF] m-auto hover-gray-700 cursor-pointer"
            icon="calendar"
            onClick={() => setIsCalendarOpen((prev) => !prev)}
          />
        </div>
      </div>
      <input
        id={id}
        type="hidden"
        name={name}
        {...(register && { ...register(name) })}
        value={watch(name) ? watch(name) : (isDateSelected && selectedDate ? selectedDate.toISOString() : null)}
        onChange={onChange}
        required={required}
      />
    </Popover>
  )
}

export default CustomDate
