import { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { capitalize } from 'lodash'
import { CustomFormElement } from 'components'
import { ApplicationDetails, Attachment, ChecklistRequirements, ChecklistRequirementsNames, SalesRepresentative } from 'interface'
import { createApplication, deleteApplicationAttachment, downloadApplicationAttachment, downloadChecklistAttachment, fetchSalesRepresentatives, previewApplicationAttachment, previewChecklistAttachment, updateApplication, uploadApplicationAttachment } from 'services'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormLabel } from 'components/common/Form'
import { AptiveIcon, Button, Checkbox } from '@aptive-env/storybook'
import LicenseApplicationFooter from './LicenseApplicationFooter'
import LicenseApplicationHeader from './LicenseApplicationHeader'
import FilePreview from 'components/common/Form/CustomFile/FilePreview'
import * as yup from 'yup'

type ApplicationFormValues = {
  primary_sales_rep_id: number
  additional_sales_rep_ids: number[]
  requirements: Partial<ChecklistRequirements>
  notes?: string
  attachments?: (File | Attachment)[]
}

const applicationFormSchema = yup.object().shape({
  primary_sales_rep_id: yup.number().required('Primary applicant is required'),
  additional_sales_rep_ids: yup.array().of(yup.number().required())
    .required()
    .min(1, 'Additional applicants are required')
    .test('not-in-primary', 'Primary applicant cannot be in additional applicants', function (value) {
      const { primary_sales_rep_id } = this.parent
      return !value || !value.includes(primary_sales_rep_id)
    }),
  requirements: yup.object().shape({
    registration_in_person: yup.boolean().optional(),
    wet_signature: yup.boolean().optional(),
    digital_signature: yup.boolean().optional(),
    background_check: yup.boolean().optional(),
    fingerprints: yup.boolean().optional(),
    identification_requirements: yup.boolean().optional(),
    photo_requirements: yup.boolean().optional(),
    bond: yup.boolean().optional(),
    certificate_of_insurance: yup.boolean().optional(),
    articles_of_incorporation: yup.boolean().optional(),
    business_license: yup.boolean().optional(),
    certificate_of_authority: yup.boolean().optional(),
    letter_of_authorization: yup.boolean().optional(),
    personal_references: yup.boolean().optional(),
    local_references: yup.boolean().optional(),
    professional_references: yup.boolean().optional(),
    sales_tax_id: yup.boolean().optional(),
    vehicle_registration: yup.boolean().optional(),
    vehicle_insurance: yup.boolean().optional(),
    additional_appointments_required: yup.boolean().optional(),
  }).required(),
  notes: yup.string().optional(),
  attachments: yup.array().of(yup.mixed<File>().required()).optional(),
})

interface LicenseApplicationFormProps {
  details: Partial<ApplicationDetails>
  attachments: Attachment[]
  checklistAttachments: Attachment[]
  onCancel: () => void
  onSubmit: ({ success, message }: { success: boolean, message: string }) => void
}

const LicenseApplicationForm: FC<LicenseApplicationFormProps> = ({
  details,
  attachments,
  checklistAttachments,
  onCancel,
  onSubmit,
}) => {
  const methods = useForm<ApplicationFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      primary_sales_rep_id: details.primary_sales_rep?.id,
      additional_sales_rep_ids: details.additional_sales_reps ? details.additional_sales_reps.map((item) => item.id) : [],
      notes: details.notes ?? undefined,
      attachments,
      requirements: Object.keys(details.requirements ?? {})
        .filter((key) => details.id
          ? typeof details.requirements?.[key as keyof ChecklistRequirements] === 'boolean'
          : details.requirements?.[key as keyof ChecklistRequirements] === true
        )
        .reduce((a, v) => {
          const key = v as keyof ChecklistRequirements
          a[key] = details.id ? details.requirements?.[key]: false
          return a
        }, {} as Partial<ChecklistRequirements>),
    },
    resolver: yupResolver<ApplicationFormValues>(applicationFormSchema),
  })

  const {
    formState: { errors, isValid, isSubmitting },
    register,
    setValue,
    getValues,
    handleSubmit,
  } = methods

  const [isRequirementsSectionExpanded, setIsRequirementsSectionExpanded] = useState(true)
  const [salesRepresentatives, setSalesRepresentatives] = useState<SalesRepresentative[]>([])

  const loadSalesRepresentatives = async () => {
    const fetchSalesRepresentativesResponse = await fetchSalesRepresentatives({
      page: 1,
      per_page: 1000000
    })
    setSalesRepresentatives(fetchSalesRepresentativesResponse.result.reps)
  }

  const handleCheckRequirement = (event: React.MouseEvent<HTMLElement, MouseEvent>, key: keyof ChecklistRequirements) => {
    event.preventDefault()
    event.stopPropagation()
    const requirements = getValues('requirements')
    const newRequirements = {
      ...requirements,
      [key]: !requirements[key],
    }
    setValue('requirements', newRequirements, { shouldValidate: true, shouldDirty: true })
  }

  const handleOpenApplicationUrl = () => {
    if (details.submission_url) {
      window.open(details.submission_url, '_blank')
    }
  }

  const handlePreviewFile = async (file: File | Attachment) => {
    if ((file as Attachment).id && details.id) {
      const response = await previewApplicationAttachment(details.id, (file as Attachment).id)
      const url = URL.createObjectURL(response)
      window.open(url, '_blank')
      URL.revokeObjectURL(url)
    } else {
      const url = URL.createObjectURL(file as File)
      window.open(url, '_blank')
      URL.revokeObjectURL(url)
    }
  }

  const handleDownloadFile = async (file: File | Attachment) => {
    if ((file as Attachment).id && details.id) {
      const attachment = file as Attachment
      const response = await downloadApplicationAttachment(details.id, attachment.id)
      const url = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = url
      link.download = attachment.filename
      link.click()
      URL.revokeObjectURL(url)
    } else {
      const asFile = file as File
      const url = URL.createObjectURL(asFile)
      const link = document.createElement('a')
      link.href = url
      link.download = asFile.name
      link.click()
      URL.revokeObjectURL(url)
    }
  }

  const handlePreviewChecklistFile = async (file: File | Attachment) => {
    if ((file as Attachment).id && details.checklist_id) {
      const response = await previewChecklistAttachment(details.checklist_id, (file as Attachment).id)
      const url = URL.createObjectURL(response)
      window.open(url, '_blank')
      URL.revokeObjectURL(url)
    } else {
      const url = URL.createObjectURL(file as File)
      window.open(url, '_blank')
      URL.revokeObjectURL(url)
    }
  }

  const handleDownloadChecklistFile = async (file: File | Attachment) => {
    if ((file as Attachment).id && details.checklist_id) {
      const attachment = file as Attachment
      const response = await downloadChecklistAttachment(details.checklist_id, attachment.id)
      const url = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = url
      link.download = attachment.filename
      link.click()
      URL.revokeObjectURL(url)
    } else {
      const asFile = file as File
      const url = URL.createObjectURL(asFile)
      const link = document.createElement('a')
      link.href = url
      link.download = asFile.name
      link.click()
      URL.revokeObjectURL(url)
    }
  }

  const handleChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target
    setValue(name as keyof ApplicationFormValues, value, { shouldValidate: true, shouldDirty: true })
  }, [setValue])

  const handleSubmitForm = useCallback(async () => {
    try {
      const values = getValues()
      const applicationId = details.id
      if (applicationId) {
        const response = await updateApplication(applicationId, {
          primary_sales_rep_id: values.primary_sales_rep_id,
          additional_sales_rep_ids: values.additional_sales_rep_ids,
          notes: values.notes,
          ...values.requirements,
        })
        if (response._metadata.success && values.attachments && values.attachments.length > 0) {
          const currentAttachmentIds = values.attachments.map((attachment) => (attachment as Attachment).id).filter((id) => id)
          const deletedAttachmentsIds = attachments
            .map((attachment) => attachment.id)
            .filter((attachmentId) => !currentAttachmentIds.includes(attachmentId))

          deletedAttachmentsIds.forEach(async (attachmentId) => {
            await deleteApplicationAttachment(applicationId, attachmentId)
          })

          for (const attachment of values.attachments) {
            if (!(attachment as Attachment).id) {
              await uploadApplicationAttachment(applicationId, attachment as File)
            }
          }
        }
        onSubmit({
          success: response._metadata.success ?? false,
          message: response.result.message
        })
      } else if (details.checklist_id) {
        const response = await createApplication({
          checklist_id: details.checklist_id,
          primary_sales_rep_id: values.primary_sales_rep_id,
          additional_sales_rep_ids: values.additional_sales_rep_ids,
          notes: values.notes,
          ...values.requirements,
        })
        if (response.result.id && values.attachments && values.attachments.length > 0) {
          for (const attachment of values.attachments) {
            await uploadApplicationAttachment(response.result.id, attachment as File)
          }
        }
        onSubmit({
          success: response._metadata.success ?? false,
          message: response.result.message
        })
      }
    } catch (e) {
      console.error(e)
      onSubmit({
        success: false,
        message: `Error in ${details.id ? 'updating' : 'creating'} application.`
      })
    }
  }, [attachments, details, getValues, onSubmit])

  useEffect(() => {
    loadSalesRepresentatives()
  }, [])

  return (
    <div className="w-[576px]">
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(handleSubmitForm)}>
          <LicenseApplicationHeader
            title={details.id ? 'Edit Application' : 'New Application'}
            onClose={onCancel}
          />
          <div className="px-6 grid grid-cols-1 py-6 gap-y-6 gap-x-4 sm:grid-cols-6 max-h-[473px] overflow-y-auto no-scrollbar">
            <div className="sm:col-span-6">
              <FormLabel
                label="Location Name"
                required={false}
                htmlFor="location"
              />
              <div>{[details.state?.name, details.county?.name, details.municipality?.name].filter((item) => item).join(' - ')}</div>
            </div>
            {details.submission_type !== 'online' && (
              <>
                <CustomFormElement
                  id="primary_sales_rep_id"
                  name="primary_sales_rep_id"
                  label="Primary applicant"
                  type="multiSelect"
                  placeholder=""
                  isMulti={false}
                  options={salesRepresentatives.map((salesRepresentative) => ({
                    value: salesRepresentative.id,
                    label: salesRepresentative.name,
                  }))}
                  onChange={handleChange}
                  value={getValues('primary_sales_rep_id')}
                  register={register}
                  error={errors.primary_sales_rep_id as any}
                  formElementWrapperClassName='sm:col-span-6'
                  required
                />
                <CustomFormElement
                  id="additional_sales_rep_ids"
                  name="additional_sales_rep_ids"
                  label="Additional applicants (you can select more than one)"
                  type="multiSelect"
                  placeholder=""
                  isMulti={true}
                  options={salesRepresentatives.map((salesRepresentative) => ({
                    value: salesRepresentative.id,
                    label: salesRepresentative.name,
                  }))}
                  onChange={handleChange}
                  value={getValues('additional_sales_rep_ids')}
                  register={register}
                  error={errors.additional_sales_rep_ids as any}
                  formElementWrapperClassName='sm:col-span-6'
                  required
                />
              </>
            )}
            <div className="sm:col-span-6">
              <FormLabel
                label="Priotity"
                required={false} 
                htmlFor="priotity"
              />
              <div className="flex items-center flex-1 py-4 text-sm text-[#000000]">
                {details.priority === 'high' && (
                  <AptiveIcon
                    className="w-[16px] h-[16px] stroke-[#F05252] fill-none"
                    icon="chevronUp"
                  />
                )}
                {details.priority === 'medium' && (
                  <AptiveIcon
                    className="w-[16px] h-[16px] stroke-[#F59E0B] fill-none"
                    icon="menuAlt4"
                  />
                )}
                {details.priority === 'low' && (
                  <AptiveIcon
                    className="w-[16px] h-[16px] stroke-[#0EA5E9] fill-none"
                    icon="chevronDown"
                  />
                )}
                <span className="pl-3">{capitalize(details.priority)}</span>
              </div>
            </div>
            {details.submission_type === 'online' && (
              <>
                <div className="sm:col-span-6">
                  <div className="text-[#4B5563]">This application is an online application. Please click the button below to open the application portal</div>
                </div>
                <div className="sm:col-span-6 flex gap-2">
                  <CustomFormElement
                    id="submission_url"
                    name="submission_url"
                    label="Submission URL"
                    placeholder="http://www.example.com/applications"
                    type="text"
                    hasCopyButton={true}
                    formElementWrapperClassName="w-full"
                    value={details.submission_url}
                    onChange={() => {}}
                  />
                  <Button
                    size="default"
                    variant="default"
                    className="mt-[22px] h-[38px] flex-none w-[200px]"
                    onClick={handleOpenApplicationUrl}
                  >
                    Open Application URL
                  </Button>
                </div>
              </>
            )}
            {details.submission_type !== 'online' && (
              <>
                <div className="sm:col-span-6">
                  <div className='flex justify-between'>
                    <div className='text-sm font-medium text-gray-700'>Requirements</div>
                    <button
                      className='flex gap-5 mr-3 cursor-pointer'
                      onClick={(e) => {
                        e.preventDefault()
                        setIsRequirementsSectionExpanded(!isRequirementsSectionExpanded)
                      }}
                    >
                      <AptiveIcon
                        className="w-5 h-5 stroke-[#4B5563] fill-none"
                        icon={isRequirementsSectionExpanded ? 'chevronUp' : 'chevronDown'}
                      />
                    </button>
                  </div>
                  {isRequirementsSectionExpanded && (
                    <div className="mt-5">
                      {Object.keys(getValues('requirements')).map((key, index) => (
                        <div key={key} className={index % 2 === 0 ? 'sm:col-span-3' : 'sm:col-span-4'}>
                          <Checkbox
                            className="rounded-sm border-gray-400"
                            checked={getValues('requirements')[key as keyof ChecklistRequirements]}
                            onClick={(event) => handleCheckRequirement(event, key as keyof ChecklistRequirements)}
                          >
                            {ChecklistRequirementsNames[key as keyof ChecklistRequirements]}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="sm:col-span-6">
                  <FilePreview
                    files={checklistAttachments}
                    onPreview={handlePreviewChecklistFile}
                    onDownload={handleDownloadChecklistFile}
                  />
                </div>
                <CustomFormElement
                  id="notes"
                  name="notes"
                  label="Notes"
                  placeholder="Something about myself"
                  rows={1}
                  type="textArea"
                  onChange={handleChange}
                  value={getValues('notes')}
                  register={register}
                  error={errors.notes as any}
                  formElementWrapperClassName='sm:col-span-6'
                />
                <CustomFormElement
                  id="attachments"
                  name="attachments"
                  type="file"
                  attachmentLabel="Attachments"
                  onChange={handleChange}
                  defaultFiles={getValues('attachments')}
                  acceptedFileTypes={['application/pdf']}
                  maxFileSize={30 * 1024 * 1024}
                  maxFileCount={1}
                  register={register}
                  error={errors.attachments as any}
                  formElementWrapperClassName='sm:col-span-6'
                  onPreviewFile={handlePreviewFile}
                  onDownloadFile={handleDownloadFile}
                />
              </>
            )}
          </div>
          {details.submission_type !== 'online' && (
            <LicenseApplicationFooter
              isCancelDisabled={false}
              isSaveDisabled={!isValid || isSubmitting}
              onCancel={onCancel}
              onSave={() => {}}
            />
          )}
        </form>
      </FormProvider>
    </div>
  )
}

export default LicenseApplicationForm
