var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import Button from '../Button';
import { Dropdown, DropdownItem } from '../Dropdown';
export var NavigationButton = function NavigationButton(_a) {
    var onClick = _a.onClick, disabled = _a.disabled, directionIcon = _a.directionIcon;
    var ariaLabel, icon;
    switch (directionIcon) {
        case 'first':
            ariaLabel = 'First';
            icon = 'chevronDoubleLeft';
            break;
        case 'prev':
            ariaLabel = 'Previous';
            icon = 'chevronLeft';
            break;
        case 'next':
            ariaLabel = 'Next';
            icon = 'chevronRight';
            break;
        case 'last':
            ariaLabel = 'Last';
            icon = 'chevronDoubleRight';
            break;
        default: break;
    }
    return (_jsx(Button, { size: "default", icon: icon, variant: 'neutral', onClick: onClick, disabled: disabled, "aria-label": ariaLabel }));
};
export var PageButton = function PageButton(_a) {
    var page = _a.page, isActive = _a.isActive, onClick = _a.onClick;
    return (_jsx(Button, __assign({ size: "default", variant: isActive ? 'default' : 'neutral', onClick: onClick }, { children: page })));
};
export var PageSizeDropdown = React.forwardRef(function (params, ref) {
    var options = params.options, value = params.value, onChange = params.onChange;
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    function toggleDropdown(event) {
        event.stopPropagation();
        setIsOpen(!isOpen);
    }
    var selectedValue = value || options[0];
    var handleSelect = function (item) { return function () {
        onChange(item);
        setIsOpen(!isOpen);
    }; };
    return (_jsxs("div", __assign({ className: "relative mr-[24px]", "aria-label": 'PageSizeDropdown' }, { children: [_jsx(Button, __assign({ className: 'min-w-[80px] justify-between', icon: "chevronDown", position: "right", variant: "neutral", onClick: toggleDropdown, isFilled: true, "aria-label": "Dropdown button", "aria-haspopup": "true" }, { children: selectedValue })), _jsx(Dropdown, __assign({ isOpen: isOpen, onClose: function () { return setIsOpen(false); } }, { children: options.map(function (option, index) {
                    return _jsx(DropdownItem, __assign({ selected: selectedValue === option, onClick: handleSelect(option) }, { children: option }), index);
                }) }))] })));
});
var Pagination = React.forwardRef(function Pagination(props, ref) {
    var totalResults = props.totalResults, currentPage = props.currentPage, perPage = props.perPage, pageSizeOptions = props.pageSizeOptions, label = props.label, onPerPageChange = props.onPerPageChange, onPageChange = props.onPageChange, other = __rest(props, ["totalResults", "currentPage", "perPage", "pageSizeOptions", "label", "onPerPageChange", "onPageChange"]);
    var _a = useState([]), pages = _a[0], setPages = _a[1];
    var _b = useState(currentPage), activePage = _b[0], setActivePage = _b[1];
    var _c = useState(perPage), resultsPerPage = _c[0], setResultsPerPage = _c[1];
    var TOTAL_PAGES = Math.ceil(totalResults / resultsPerPage);
    var FIRST_PAGE = 1;
    var LAST_PAGE = TOTAL_PAGES;
    function handleFirstClick() {
        setActivePage(1);
    }
    function handlePreviousClick() {
        setActivePage(activePage - 1);
    }
    function handleNextClick() {
        setActivePage(activePage + 1);
    }
    function handleLastClick() {
        setActivePage(TOTAL_PAGES);
    }
    var onChangePagesize = useCallback(function (pageSize) { return setResultsPerPage(pageSize); }, []);
    useEffect(function () {
        setPages([
            activePage
        ]);
    }, [activePage, TOTAL_PAGES]);
    useEffect(function () {
        setActivePage(currentPage);
    }, [currentPage]);
    useEffect(function () {
        onPageChange(activePage);
    }, [activePage, onPageChange]);
    useEffect(function () {
        onPerPageChange(resultsPerPage);
    }, [resultsPerPage, onPerPageChange]);
    var pagination = useContext(ThemeContext).theme.pagination;
    var baseStyle = pagination.base;
    return (_jsxs("div", __assign({ className: baseStyle, ref: ref }, other, { "aria-label": 'Pagination' }, { children: [_jsxs("span", __assign({ className: "flex items-center font-semibold tracking-wide min-w-[200px]" }, { children: ["Showing ", activePage * resultsPerPage - resultsPerPage + 1, " to ", Math.min(activePage * resultsPerPage, totalResults), " of ", totalResults, " results"] })), _jsxs("div", __assign({ className: "flex mt-2 sm:mt-auto sm:justify-end" }, { children: [_jsx("div", __assign({ className: "flex items-center font-semibold tracking-wide mr-[6px] min-w-[90px]" }, { children: "Rows per page" })), _jsx("div", { children: _jsx(PageSizeDropdown, { options: pageSizeOptions, value: resultsPerPage, onChange: onChangePagesize }) }), _jsx("nav", __assign({ "aria-label": label }, { children: _jsxs("ul", __assign({ className: "inline-flex items-center space-x-1" }, { children: [_jsx("li", { children: _jsx(NavigationButton, { directionIcon: "first", disabled: activePage === FIRST_PAGE, onClick: handleFirstClick }) }), _jsx("li", { children: _jsx(NavigationButton, { directionIcon: "prev", disabled: activePage === FIRST_PAGE, onClick: handlePreviousClick }) }), pages.map(function (p, i) { return (_jsx("li", { children: _jsx(PageButton, { page: p, isActive: p === activePage, onClick: function () { return setActivePage(+p); } }) }, p.toString() + i)); }), _jsxs("li", __assign({ className: 'min-w-[28px]' }, { children: [" of ", TOTAL_PAGES] }), TOTAL_PAGES.toString() + 1), _jsx("li", { children: _jsx(NavigationButton, { directionIcon: "next", disabled: activePage === LAST_PAGE, onClick: handleNextClick }) }), _jsx("li", { children: _jsx(NavigationButton, { directionIcon: "last", disabled: activePage === LAST_PAGE, onClick: handleLastClick }) })] })) }))] }))] })));
});
export default Pagination;
