import ChecklistForm from './ChecklistForm'
import { Modal } from '@aptive-env/storybook'
import { State, Checklist, Attachment } from 'interface'

interface ChecklistModalProps {
  isOpen: boolean
  checklist: Checklist | null
  checklistAttachments: Attachment[]
  states: State[]
  onCancel: () => void
  onSubmit: ({ success, message }: { success: boolean, message: string }) => void
}

const ChecklistModal: React.FC<ChecklistModalProps> = ({ 
  isOpen,
  checklist,
  checklistAttachments,
  states,
  onCancel,
  onSubmit,
}) => {
  return (
    <Modal open={isOpen} onClickOutside={onCancel}>
      <ChecklistForm
        checklist={checklist}
        checklistAttachments={checklistAttachments}
        states={states}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </Modal>
  )
}

export default ChecklistModal
