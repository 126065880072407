import { FC, ReactNode, useEffect, useRef } from 'react'

export type PopoverProps = {
  open: boolean
  content: ReactNode
  children: ReactNode
  position: 'top' | 'bottom' | 'left' | 'right'
  className?: string
  onClickOutside?: () => void
}

const Popover: FC<PopoverProps> = ({
  open,
  children,
  content,
  position,
  className = '',
  onClickOutside,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside && onClickOutside()
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [
    onClickOutside
  ])

  return (
    <div className={open ? 'relative' : ''} ref={ref}>
      {children}
      {open && (
        <div
          className={`bg-white rounded absolute z-10 ${className} ${position === 'top' ? 'bottom-[100%] mb-2' : position === 'bottom' ? 'mt-2' : position === 'right' ? 'mt-2 left-0' : position === 'left' ? 'mt-2 right-0' : '' }`}
          style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
        >
          {content}
        </div>
      )}
    </div>
  )
}

export default Popover
