import { FC } from 'react'
import { AptiveIcon } from '@aptive-env/storybook'

interface ILicenseHeaderProps {
  label: string
  onClose: () => void
}

const LicenseHeader: FC<ILicenseHeaderProps> = ({ label, onClose }) => {
  return (
    <div className="w-full h-[89px] flex items-center justify-between border-b border-gray-200 px-6">
      <div className="text-2xl text-gray-900 font-bold">{ label }</div>
      <div className="cursor-pointer" onClick={onClose}>
        <AptiveIcon
          className="w-4 h-4 fill-[#9CA3AF] stroke-none"
          icon="x"
          isFilled
        />
      </div>
    </div>
  )
}

export default LicenseHeader
