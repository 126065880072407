import { State } from './state'
import { County } from './county'
import { Municipality } from './municipality'
import { Attachment } from './attachment'

export type ChecklistPriority = 'high' | 'medium' | 'low'
export type ChecklistSubmissionType  = 'email' | 'online' | 'in-person'

export interface Checklist {
  id: number
  state: State
  county: County | null
  municipality: Municipality | null
  priority: ChecklistPriority
  attachments: Attachment[]
  attachments_count: number
  created_at: string
  requirements: ChecklistRequirements
  submission_email: string
  submission_type: ChecklistSubmissionType
  submission_url: string
  city_contact_email: string | null
  city_contact_name: string | null
  city_contact_phone: string | null
  notes: string | null
  hidden?: boolean
}

export interface ChecklistRequirements {
  registration_in_person: boolean
  wet_signature: boolean
  digital_signature: boolean
  background_check: boolean
  fingerprints: boolean
  identification_requirements: boolean
  photo_requirements: boolean
  bond: boolean
  certificate_of_insurance: boolean
  articles_of_incorporation: boolean
  business_license: boolean
  certificate_of_authority: boolean
  letter_of_authorization: boolean
  personal_references: boolean
  local_references: boolean
  professional_references: boolean
  sales_tax_id: boolean
  vehicle_registration: boolean
  vehicle_insurance: boolean
  additional_appointments_required: boolean
}

export const ChecklistRequirementsNames: Record<keyof ChecklistRequirements, string> = {
  registration_in_person: 'Registration in person',
  wet_signature: 'Wet signature',
  digital_signature: 'Digital signature',
  background_check: 'Background Check',
  fingerprints: 'Fingerprints',
  identification_requirements: 'Identification requirements',
  photo_requirements: 'Headshot/photo requirement',
  bond: 'Bond',
  certificate_of_insurance: 'Certificate of Insurance',
  articles_of_incorporation: 'Articles of Incorporation',
  business_license: 'Business License (additional to individual)',
  certificate_of_authority: 'Certificate of Authority',
  letter_of_authorization: 'Letter of Authorization',
  personal_references: 'References (personal)',
  local_references: 'References (local)',
  professional_references: 'References (professional)',
  sales_tax_id: 'Sales Tax ID # or certificate',
  vehicle_registration: 'Vehicle Registration',
  vehicle_insurance: 'Vehicle Insurance',
  additional_appointments_required: 'Additional appointments required',
}
