import { Loader } from 'components/common'
import { mergeClassName } from 'lib'
import { forwardRef, useCallback, useMemo } from 'react'
import {
  components,
  ControlProps as RSControlProps,
  InputProps as RSInputProps, 
  MenuProps as RSMenuProps,
  MenuListProps as RSMenuListProps,
  OptionProps as RSOptionProps,
  default as BaseSelect
} from 'react-select'

interface IControlProps extends RSControlProps {
  isFocused: boolean
  children: React.ReactNode,
  selectProps: any,
}

interface IInputProps extends RSInputProps{
  inputClassName?: string;
}

interface IMenuProps extends RSMenuProps {
  children: React.ReactNode;
}

interface IMenuListProps extends RSMenuListProps {
  children: React.ReactNode;
}

interface IOptionProps extends RSOptionProps {
  isSelected: boolean;
  isFocused: boolean;
  children: React.ReactNode;
}

interface ISelectProps {
  inputId: string;
  name: string;
  value: string;
  options: { label: string; value: string }[];
  defaultOption: { label: string; value: string };
  onChange: (event: { target: { name: string; value: string } }) => void;
  isLoading: boolean;
  className: string;
  styles: Record<string, any>;
  isMulti: boolean;
  closeMenuOnSelect: boolean;
  required: boolean;
  hasError: boolean;
  onInputChange: (inputValue: string) => void;
  filterOption: (option: { label: string; value: string }, inputValue: string) => boolean;
  defaultInputValue: string;
  placeholder: string;
  disabled: boolean;
  ref: React.Ref<any>;
}

const Control: React.FC<IControlProps> = (props) => {
  const { isFocused, children, selectProps, ...rest } = props
  const { hasError } = selectProps

  const className = mergeClassName(
    'bg-white border rounded-md text-sm',
    { 'ring-1': isFocused },
    { 'text-gray-500': !hasError },
    { 'text-red-300': hasError },
    { 'border-gray-300': !isFocused && !hasError },
    { 'border-red-300': !isFocused && hasError },
    { 'ring-primary-300 border-primary-300': isFocused && !hasError },
    { 'ring-red-500 border-red-500': isFocused && hasError }
  )

  return (
    <components.Control {...rest} isFocused={isFocused} className={className} selectProps={selectProps}>
      {children}
    </components.Control>
  )
}

const Input: React.FC<IInputProps> = (props) => {
  return <components.Input {...props} inputClassName="focus:ring-0" />
}

const LoadingIndicator = () => <Loader />

const IndicatorSeparator = () => null

const Menu: React.FC<IMenuProps> = ({ children, ...props }) => (
  <components.Menu
    {...props}
    className="border border-gray-300 text-sm text-gray-600"
  >
    {children}
  </components.Menu>
)

const MenuList: React.FC<IMenuListProps> = ({ children, ...props }) => (
  <components.MenuList {...props} className="divide-y divide-gray-100">
    {children}
  </components.MenuList>
)

const Option: React.FC<IOptionProps> = (props) => {
  const { isSelected, isFocused, children, ...rest } = props

  const className = mergeClassName(
    'hover:bg-gray-50',
    { 'bg-gray-50': isSelected },
    { 'text-primary-300': isFocused || isSelected }
  )

  return (
    <components.Option {...rest} className={className} isSelected={isSelected} isFocused={isFocused}>
      {children}
    </components.Option>
  )
}

const Select: React.FC<ISelectProps> = ({
  inputId,
  name,
  value,
  options,
  defaultOption,
  onChange,
  isLoading,
  className,
  styles,
  isMulti,
  closeMenuOnSelect,
  required,
  hasError,
  onInputChange,
  filterOption,
  defaultInputValue,
  placeholder,
  disabled,
  ref,
}) => {
  const onChangeWrapper = useCallback(
    (newValue) => {
      if (Array.isArray(newValue)) {
        newValue = newValue.map(({ value }) => value)
      } else {
        newValue = newValue?.value
      }

      onChange({
        target: {
          name: name,
          value: newValue,
        },
      })
    },
    [name, onChange]
  )

  const defaultStyles = useMemo(
    () => ({
      control: (provided: any) => ({
        ...provided,
        '&:hover': null,
        borderColor: null,
        borderRadius: null,
        transition: null,
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        padding: '2px 12px',
      }),
      singleValue: (provided: any) => ({
        ...provided,
        marginLeft: null,
        marginRight: null,
        color: null,
      }),
      input: (provided: any) => ({
        ...provided,
        margin: null,
        color: null,
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        ':hover': null,
        color: null,
      }),
      menu: (provided: any) => ({
        ...provided,
        overflow: 'hidden',
        borderRadius: '8px',
        boxShadow: null,
        marginTop: '4px',
        marginBottom: '4px',
      }),
      menuList: (provided: any) => ({
        ...provided,
        maxHeight: 4 + 7 * 41 + 4, // paddingTop + 7 options + paddingBottom
      }),
      menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 9999,
      }),
      option: (provided: any) => ({
        ...provided,
        ':active': null,
        padding: '10px 16px',
        backgroundColor: null,
        color: null,
        cursor: 'pointer',
      }),
      loadingMessage: (provided: any) => ({
        ...provided,
        padding: '10px 16px',
        color: null,
      }),
      noOptionsMessage: (provided: any) => ({
        ...provided,
        padding: '10px 16px',
        color: null,
      }),
    }),
    []
  )

  const baseValue = useMemo(() => {
    if (isMulti) {
      return options.filter((option) => value?.includes(option.value))
    }

    return options.find((option) => option.value === value) || defaultOption
  }, [defaultOption, isMulti, options, value])

  return (
    <BaseSelect
      inputId={inputId}
      name={name}
      value={baseValue}
      options={options}
      onChange={onChangeWrapper}
      isLoading={isLoading}
      className={className}
      styles={{ ...defaultStyles, ...styles }}
      components={{
        Control,
        Input,
        LoadingIndicator,
        IndicatorSeparator,
        Menu,
        MenuList,
        Option,
      }}
      menuPortalTarget={document.body}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      required={required}
      placeholder={placeholder}
      isDisabled={disabled}
      onInputChange={onInputChange}
      filterOption={filterOption}
      {...(defaultInputValue && { defaultInputValue })}
      {...(ref && { ref })}
      menuPosition={'fixed'}
    />
  )
}

Select.defaultProps = {
  isLoading: false,
}

export default forwardRef((props: ISelectProps, ref) => <Select {...props} ref={ref} />)
