import React from 'react'
import { AptiveIcon } from '@aptive-env/storybook'
import FileUpload from './FileUpload'

interface IFileUploadProps {
  isDragging: boolean;
  handleDragEnter: (event: React.DragEvent) => void;
  handleDragLeave: (event: React.DragEvent) => void;
  handleDragOver: (event: React.DragEvent) => void;
  handleDrop: (event: React.DragEvent) => void;
  handleFileUpload: (files: FileList) => void;
  acceptedFileTypes: string[];
  maxFileSize: number;
}

const FILE_TYPES: Record<string, string> = {
  'application/pdf': 'PDF',
  'image/jpeg': 'JPG',
  'image/png': 'PNG'
}

const DropZone: React.FC<IFileUploadProps> = ({
  isDragging,
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop,
  handleFileUpload,
  acceptedFileTypes,
  maxFileSize
}) => {
  return (
    <div
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={`grid gap-2 border-2 border-dashed p-10 text-center rounded-lg bg-gray-50 h-[228px] ${
        isDragging ? 'border-blue-500 bg-gray-300' : 'border-gray-300'
      }`}
    >
      <FileUpload onFileUpload={handleFileUpload} acceptedFileTypes={acceptedFileTypes} />
      <label htmlFor="file-upload" className="block cursor-pointer">
        <div className='grid gap-2'>
          <AptiveIcon
            className="w-5 h-5 stroke-[#9CA3AF] fill-none m-auto cursor-pointer"
            icon="upload"
          />
          <div className="flex m-auto">
            <span className='font-semibold text-sm text-gray-500'>Click to upload</span>&nbsp;
            <span className='font-normal text-sm text-gray-500'>or drag and drop</span>
          </div>
          <p className="text-xs font-semibold text-gray-500">Upload a file {acceptedFileTypes.map((fileType) => FILE_TYPES[fileType]).join(', ')} up to {maxFileSize / (1024 * 1024)}MB</p>
          <div className="flex gap-[3px] items-center px-4 py-2 mt-2 bg-blue-500 text-white rounded-lg w-[110px] m-auto hover:bg-blue-600">
            <AptiveIcon
              className="w-3 h-3 stroke-white fill-none m-auto cursor-pointer"
              icon="search"
            />
            <span className='text-xs whitespace-nowrap'>Browse File</span>
          </div>
        </div>
      </label>
    </div>
  )
}

export default DropZone
