import React, { PropsWithChildren } from 'react'

import MainHeader from 'components/Header'
import { Toast } from '@aptive-env/storybook'
import { useToast } from 'hooks/useToast'

interface MainLayoutProps {
  children?: React.ReactNode;
}

const MainLayout: React.FC<PropsWithChildren<MainLayoutProps>> = ({ children }) => {
  const { toast, setToast } = useToast()

  return (
    <>
      <div className="flex flex-col h-full" data-testid="MainLayout">
        <MainHeader />
        <div className="flex flex-col flex-1 bg-gray-50 overflow-auto">
          {children}
        </div>
      </div>
      {toast && (
        <div className="fixed top-3 right-3 flex flex-col gap-2 z-[100]">
          <Toast
            dismissible
            isIcon
            onDismiss={() => setToast(null)}
            status={toast.type}
          >
            {toast.message}
          </Toast>
        </div>
      )}
    </>
  )
}

export default MainLayout
