import { Pagination, SalesRepresentative, License } from 'interface'
import { apiClient } from './config'

export const fetchSalesRepresentatives = async (params: {
  season_id?: number
  team_id?: number
  license?: number
  search?: string
  sortBy?: string
  orderBy?: string
  page?: number
  per_page?: number
}) : Promise<{
  _metadata: {
    success: boolean,
    pagination: Pagination
  },
  result: {
    reps: SalesRepresentative[]
  }
}> => {
  const filteredParams = Object.fromEntries(
    Object.entries(params)
      .filter(([_, value]) => value !== undefined)  // filter out undefined values
      .map(([key, value]) => [key, value.toString()]) // convert values to strings
  )
  const response = await apiClient(`reps?${new URLSearchParams(filteredParams)}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const fetchSalesRepresentativesList = async (params: {
  season_id?: number,
  search?: string,
  team_id?: number,
}) : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    reps: SalesRepresentative[]
  }
}> => {
  const filteredParams = Object.fromEntries(
    Object.entries(params)
      .filter(([_, value]) => value !== undefined)  // filter out undefined values
      .map(([key, value]) => [key, value.toString()]) // convert values to strings
  )
  const response = await apiClient(`reps/list?${new URLSearchParams(filteredParams)}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const fetchSalesRepresentativeProfile = async (id: number) : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    rep: SalesRepresentative
  }
}> => {
  const response = await apiClient(`reps/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const fetchSalesRepresentativeLicenses = async (id: number, search: string = '') : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    licenses: License[]
  }
}> => {
  let fetchURL = `reps/${id}/licenses`

  if (search)
    fetchURL += `?search=${search}`

  const response = await apiClient(fetchURL, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const deleteSalesRepresentativeLicense = async (repId: number, licenseId: number) : Promise<{
  _metadata: {
    success: boolean
  },
  results: {
    message: string
  }
}> => {
  const response = await apiClient(`reps/${repId}/licenses/${licenseId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const exportSalesRepresentatives = async () => {
  const response = await apiClient('reps', {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/csv',
    },
    responseMode: 'text'
  })

  return response
}
