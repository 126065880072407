import { FC } from 'react'
import { AptiveIcon } from '@aptive-env/storybook'

interface DirectionProps {
  sort: number
}

const Direction: FC<DirectionProps> = ({ sort }) => {
  return (
    <div className="relative w-[16px] h-[16px]">
      <AptiveIcon
        className={`absolute -top-1 w-[16px] h-[16px] stroke-[#4b5563] fill-none ${sort > 0 ? '!stroke-[#030712]' : ''}`}
        icon='chevronUp'
      />
      <AptiveIcon
        className={`absolute -bottom-1 w-[16px] h-[16px] stroke-[#4b5563] fill-none ${sort < 0 ? '!stroke-[#030712]' : ''}`}
        icon='chevronDown'
      />
    </div>
  )
}

export default Direction
