import { Modal } from '@aptive-env/storybook'
import { FC, useEffect, useMemo, useState } from 'react'
import ChecklistSelectModalHeader from './ChecklistSelectModalHeader'
import ChecklistSelectModalTable from './ChecklistSelectModalTable'
import { Checklist } from 'interface'
import { fetchChecklists } from 'services'

interface ChecklistSelectModalProps {
  isOpen: boolean
  onClose: () => void
  onSelectChecklist: (checklist: Checklist) => void
}

const ChecklistSelectModal: FC<ChecklistSelectModalProps> = ({
  isOpen,
  onClose,
  onSelectChecklist
}) => {
  const [checklists, setChecklists] = useState<Checklist[]>([])
  const [searchValue, setSearchValue] = useState('')

  const searchedChecklists = useMemo(() => {
    return checklists.filter((requirement) =>
      requirement.state?.name.toLowerCase().includes(searchValue.toLowerCase())
      || requirement.county?.name.toLowerCase().includes(searchValue.toLowerCase())
      || requirement.municipality?.name.toLowerCase().includes(searchValue.toLowerCase())
      || requirement.priority.toLowerCase().includes(searchValue.toLowerCase())
    )
  }, [checklists, searchValue])

  useEffect(() => {
    (async () => {
      const response = await fetchChecklists({
        page: 1,
        per_page: 1000000,
      })
      setChecklists(response.result.checklists)
    })()
  }, [])

  return (
    <Modal
      open={isOpen}
      onClickOutside={onClose}
    >
      <ChecklistSelectModalHeader
        searchValue={searchValue}
        onChangeSearchValue={setSearchValue}
        onClose={onClose}
      />
      <ChecklistSelectModalTable
        checklists={searchedChecklists}
        onSelectChecklist={onSelectChecklist}
      />
    </Modal>
  )
}

export default ChecklistSelectModal
