import { FC, useState, useMemo, useCallback, useEffect } from 'react'
import { Button, Dropdown, DropdownItem, AptiveIcon, Badge } from '@aptive-env/storybook'
import debounce from 'lodash.debounce'

import { License, State, County, Municipality } from 'interface'
import { fetchCounties, fetchMunicipalities } from 'services'

const statuses = ['All', 'Active', 'Pending', 'Expired', 'Not Started']

interface IDetailLicensingProps {
  licenses: License[];
  states: State[];
  search: string;
  onSearch: (search: string) => void;
  onAdd: () => void;
  onEdit: (license: License) => void;
  onDelete: (license: License) => void;
}

const DetailLicensing: FC<IDetailLicensingProps> = ({ licenses, states, search, onSearch, onAdd, onEdit, onDelete }) => {
  const [isStateChanged, setIsStateChanged] = useState(false)
  const [searchValue, setSearchValue] = useState(search)
  const [counties, setCounties] = useState<County[]>([])
  const [municipalities, setMunicipalities] = useState<Municipality[]>([])

  const fetchCountiesByState = async (stateAbbr: string) => {
    const fetchCountiesResponse = await fetchCounties({ state_abbr: stateAbbr })
    setCounties(fetchCountiesResponse.result.counties)
  }

  const fetchMunicipalitiesByState = async (stateAbbr: string) => {
    const fetchMunicipalitiesResponse = await fetchMunicipalities({ state_abbr: stateAbbr })
    setMunicipalities(fetchMunicipalitiesResponse.result.municipalities)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(debounce(onSearch, 500), [])

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    debouncedSearchHandler(event.target.value)
  }

  const [filterValues, setFilterValues] = useState<Record<string, string | number>>({
    state: '',
    county: 0,
    municipality: 0
  })
  const [dropdownsOpen, setDropdownsOpen] = useState<Record<string, boolean>>({
    state: false,
    county: false,
    municipality: false
  })
  const [status, setStatus] = useState(0)

  const handleDropdownToggle = (key: string, value?: boolean) => {
    const tempDropdownsOpen = { ...dropdownsOpen }
    Object.keys(tempDropdownsOpen).forEach((dropdownKey) => {
      tempDropdownsOpen[dropdownKey] = dropdownKey === key ? value ?? !tempDropdownsOpen[dropdownKey] : false
    })

    setDropdownsOpen(tempDropdownsOpen)
  }

  const onSetFilter = (key: string, value: string | number) => {
    const tempFilterValues = { ...filterValues }
    tempFilterValues[key] = tempFilterValues[key] === value ? '' : value
    setFilterValues(tempFilterValues)

    if (key === 'state') {
      setIsStateChanged(true)
    }
  }

  const filteredLicenses = useMemo(() => {
    const tempLicenses = licenses.filter((license) => {
      if (license.hidden)
        return false

      const filterMatched = Object.keys(filterValues).every((key) => {
        const licenseKey = key as keyof License
        switch (key) {
        case 'state':
          return !filterValues[key] || (license[licenseKey] as State)?.abbreviation === filterValues[key]  
        case 'county':
          return !filterValues[key] || (license[licenseKey] as County)?.id === filterValues[key]  
        case 'municipality':
          return !filterValues[key] || (license[licenseKey] as Municipality)?.id === filterValues[key]  
        }
        return false
      })

      return filterMatched
    })
    
    const groupedLicenses: Record<number, License[]> = {
      0: tempLicenses,
      1: tempLicenses.filter((license) => license.status === 'active'),
      2: tempLicenses.filter((license) => license.status === 'pending'),
      3: tempLicenses.filter((license) => license.status === 'expired'),
      4: tempLicenses.filter((license) => license.status === 'not_started'),
    }
    return groupedLicenses
  }, [filterValues, licenses])

  const getSelectedValueName = (key: string, value: string | number) => {
    switch (key) {
    case 'state':
      return states.find((state) => state.abbreviation === value)?.name
    case 'county':
      return counties.find((county) => county.id === value)?.name
    case 'municipality':
      return municipalities.find((municipality) => municipality.id === value)?.name
    default:
      return ''
    }
  }

  const getBadgeColor = (status: string) => {
    switch (status) {
    case 'active':
      return 'success'
    case 'pending':
      return 'default'
    case 'expired':
      return 'error'
    case 'not_started':
      return 'warning'
    default:
      return 'gray'
    }
  }

  const getBadgeIcon = (status: string) => {
    switch (status) {
    case 'active':
      return 'check'
    case 'pending':
      return 'clock'
    case 'expired':
      return 'x'
    case 'not_started':
      return 'informationCircle'
    default:
      return ''
    }
  }

  const getStatusLabel = (status: string) => {
    const tempLabel = status.split('_').join(' ')
    return tempLabel.charAt(0).toUpperCase() + tempLabel.slice(1)
  }

  useEffect(() => {
    if (isStateChanged) {
      if (filterValues.state) {
        fetchCountiesByState(filterValues.state as string)
        fetchMunicipalitiesByState(filterValues.state as string)
      } else {
        setCounties([])
        setMunicipalities([])
      }
      onSetFilter('county', 0)
      onSetFilter('municipality', 0)
      setIsStateChanged(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStateChanged])

  return (
    <div className="flex flex-col grow relative px-6 pb-6 overflow-auto no-scrollbar">
      <div className="flex items-center justify-between min-h-[89px]">
        <p className="font-bold text-2xl">Licensing</p>
        <Button
          icon="plusCircle"
          size="default"
          variant="default"
          onClick={onAdd}
        >
          Add License
        </Button>
      </div>
      <div className="flex flex-col gap-0.5 mb-4">
        <label className="text-sm text-gray-600">Search</label>
        <div className="flex items-center bg-white border border-[#E5E7EB] rounded-md h-[38px] overflow-hidden">
          <input
            className="px-3 w-full focus-visible:outline-none text-[#3d3d3d] text-sm placeholder-[#A9A9A9] border-none focus:border-none focus:ring-0"
            value={searchValue}
            onChange={onInputChange}
            placeholder="Search"
          />
          <div className="px-3 border-l h-full flex items-center">
            <AptiveIcon className="w-[20px] h-[20px] stroke-[#9CA3AF] fill-none" icon="search" />
          </div>
        </div>
      </div>
      <div className="flex items-center mb-4 gap-4">
        <div className="flex flex-col flex-1">
          <label className="text-sm text-gray-600 mb-0.5">State</label>
          <Button
            className="w-full bg-white border border-gray-200 text-[#9A9A9A] !justify-between !text-sm !rounded-md"
            icon="chevronDown"
            position="right"
            variant="neutral"
            onClick={(event) => {
              event.stopPropagation()
              handleDropdownToggle('state')
            }}
            isFilled
            aria-label="Dropdown button"
            aria-haspopup="true"
          >
            <span className="text-nowrap">{getSelectedValueName('state', filterValues['state']) || 'Select State'}</span>
          </Button>
          <Dropdown isOpen={dropdownsOpen['state']} onClose={() => handleDropdownToggle('state', false)} defaultValue={filterValues['state']} className="!mt-0.5 max-h-[200px] overflow-auto">
            {states.map((state) => (
              <DropdownItem key={state.abbreviation} selected={filterValues['state'] === state.abbreviation} onClick={() => onSetFilter('state', state.abbreviation)}>
                {state.name}
              </DropdownItem> 
            ))}
          </Dropdown>
        </div>

        <div className="flex flex-col flex-1">
          <label className="text-sm text-gray-600 mb-0.5">County</label>
          <Button
            className="w-full bg-white border border-gray-200 text-[#9A9A9A] !justify-between !text-sm !rounded-md"
            icon="chevronDown"
            position="right"
            variant="neutral"
            onClick={(event) => {
              event.stopPropagation()
              handleDropdownToggle('county')
            }}
            isFilled
            aria-label="Dropdown button"
            aria-haspopup="true" 
            disabled={counties.length === 0}
          >
            <span className="text-nowrap">{getSelectedValueName('county', filterValues['county']) || 'Select County'}</span>
          </Button>
          <Dropdown isOpen={dropdownsOpen['county']} onClose={() => handleDropdownToggle('county', false)} defaultValue={filterValues['county']} className="!mt-0.5 max-h-[200px] overflow-auto">
            {counties.map((county) => (
              <DropdownItem key={county.id} selected={filterValues['county'] === county.id} onClick={() => onSetFilter('county', county.id)}>
                {county.name}
              </DropdownItem> 
            ))}
          </Dropdown>
        </div>

        <div className="flex flex-col flex-1">
          <label className="text-sm text-gray-600 mb-0.5">Municipality</label>
          <Button
            className="w-full bg-white border border-gray-200 text-[#9A9A9A] !justify-between !text-sm !rounded-md"
            icon="chevronDown"
            position="right"
            variant="neutral"
            onClick={(event) => {
              event.stopPropagation()
              handleDropdownToggle('municipality')
            }}
            isFilled
            aria-label="Dropdown button"
            aria-haspopup="true"
            disabled={municipalities.length === 0}
          >
            <span className="text-nowrap">{getSelectedValueName('municipality', filterValues['municipality']) || 'Select Municipality'}</span>
          </Button>
          <Dropdown isOpen={dropdownsOpen['municipality']} onClose={() => handleDropdownToggle('municipality', false)} defaultValue={filterValues['municipality']} className="!mt-0.5 max-h-[200px] overflow-auto">
            {municipalities.map((municipality) => (
              <DropdownItem key={municipality.id} selected={filterValues['municipality'] === municipality.id} onClick={() => onSetFilter('municipality', municipality.id)}>
                {municipality.name}
              </DropdownItem> 
            ))}
          </Dropdown>
        </div>
      </div>
      <div className="flex flex-col mt-2 gap-6">
        <div className="flex items-center gap-[31px] border-b border-gray-200">
          {statuses.map((item, index) => (
            <div
              className={`flex items-center gap-[11px] pb-6 cursor-pointer ${status === index ? 'border-b-2 border-[#000]' : ''}`}
              key={item}
              onClick={() => setStatus(index)}
            >
              <span className="text-sm text-gray-600">{item}</span>
              <Badge
                className={`px-2.5 py-0.5 !rounded-full ${status === index ? '!bg-[#007BFF] !text-[#FFF]' : '!bg-gray-300 !text-gray-600'}`}
              >
                {filteredLicenses[index].length}
              </Badge>
            </div>
          ))}
        </div>
        <div className="flex flex-col">
          <div className="flex rounded-t-lg border border-gray-200 bg-gray-50">
            <div className="px-4 py-3 text-xs text-gray-600 uppercase w-[81px]">State</div>
            <div className="px-4 py-3 text-xs text-gray-600 uppercase w-[126px]">County</div>
            <div className="px-4 py-3 text-xs text-gray-600 uppercase w-[145px]">Municipality</div>
            <div className="px-4 py-3 text-xs text-gray-600 uppercase w-[133px] text-center">Status</div>
            <div className="px-4 py-3 text-xs text-gray-600 uppercase grow text-right">Actions</div>
          </div>
          {filteredLicenses[status].map((license, index) => (
            <div key={index} className="flex border border-t-0 border-gray-200 last-of-type:rounded-b-lg">
              <div className="px-4 py-3 text-xs text-gray-600 w-[81px]">{license.state?.name}</div>
              <div className="px-4 py-3 text-xs text-gray-600 w-[126px]">{license.county?.name}</div>
              <div className="px-4 py-3 text-xs text-gray-600 w-[145px]">{license.municipality?.name}</div>
              <div className="px-2 py-3 w-[133px] text-center">
                <Badge
                  color={getBadgeColor(license.status)}
                  className="!text-sm !font-normal !px-2.5"
                >
                  <AptiveIcon
                    className="w-[14px] h-[14px] fill-none stroke-[currentColor] mr-1"
                    icon={getBadgeIcon(license.status)}
                  />
                  <span>{getStatusLabel(license.status)}</span>
                </Badge>
              </div>
              <div className="px-4 py-3 grow flex items-center justify-end gap-4">
                {license.status === 'active' && (
                  <AptiveIcon
                    className="w-[20px] h-[20px] fill-none stroke-[#9CA3AF] cursor-pointer"
                    icon="pencilAlt"
                    onClick={() => onEdit(license)}
                  />
                )}
                <AptiveIcon
                  className="w-[20px] h-[20px] fill-none stroke-[#9CA3AF] cursor-pointer"
                  icon="trash"
                  onClick={() => onDelete(license)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DetailLicensing
