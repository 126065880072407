export const licenseModalConstants = {
  NEW_LICENSE: 'New License',
  EDIT_LICENSE: 'Edit License',
  REP_LABEL: 'Rep Name',
  REP_NAME: 'rep_id',
  STATE_LABEL: 'State',
  STATE_NAME: 'state_id',
  COUNTY_LABEL: 'County',
  COUNTY_NAME: 'county_id',
  MUNICIPALITY_LABEL: 'Municipality',
  MUNICIPALITY_NAME: 'municipality_id',
  LICENSE_NUMBER_LABEL: 'License number',
  LICENSE_NUMBER_NAME: 'license_number',
  LICENSE_NUMBER_PLACEHOLDER: 'Enter license number',
  START_DATE_LABEL: 'Start date',
  START_DATE_NAME: 'start_date',
  SUBMISSION_DATE_LABEL: 'Submission date',
  SUBMISSION_DATE_NAME: 'submission_date',
  EXPIRATION_DATE_LABEL: 'Expiration date',
  EXPIRATION_DATE_NAME: 'expiration_date',
  ISSUED_DATE_LABEL: 'Issued date',
  ISSUED_DATE_NAME: 'issued_date',
  NOTES_LABEL: 'Notes',
  NOTES_NAME: 'notes',
  NOTES_PLACEHOLDER: 'Something about myself',
  ATTACHMENTS_LABEL: 'Attachments',
  ATTACHMENTS_DESCRIPTION: 'Please upload an image or a pdf copy of license.',
}
