import {FC} from 'react'
import { CustomButtonGroup } from 'components/common'

interface LicenseApplicationFooterProps {
  isCancelDisabled: boolean;
  isSaveDisabled: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const LicenseApplicationFooter: FC<LicenseApplicationFooterProps> = ({
  isCancelDisabled,
  isSaveDisabled,
  onCancel,
  onSave,
}) => {
  return (
    <div className="w-full h-16 flex justify-end items-center border-t border-gray-200 px-6">
      <CustomButtonGroup
        onCancelClick={onCancel}
        onSaveClick={onSave}
        disabledCancel={isCancelDisabled}
        disabledSave={isSaveDisabled}
        cancelText="Cancel"
        saveText="Save"
        buttonClassName="w-20"
        wrapperClassName="flex justify-end w-full"
        orientation="right"
        loading={false}
        withSubmit={true}
      />
    </div>
  )
}

export default LicenseApplicationFooter
