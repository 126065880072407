import { memo } from 'react'
import { AptiveIcon } from '@aptive-env/storybook'
interface ICustomErrorMessageProps {
  text?: string;
  isicon?: boolean;
}

const CustomErrorMessage: React.FC<ICustomErrorMessageProps> = ({ text, isicon }) => (
  <div className='flex flex-row items-center gap-1'>
    {isicon && <AptiveIcon className="w-[25px] h-[25px] stroke-[#DC2626] fill-none" icon="exclamationCircle" />}
    <div className="font-medium text-sm text-red-600">{text}</div>
  </div>
)

export default memo(CustomErrorMessage)
