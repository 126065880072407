import { Attachment, Checklist, ChecklistPriority, ChecklistRequirements, ChecklistSubmissionType, Pagination } from 'interface'
import { apiClient } from './config'

export const fetchChecklists = async (params: {
  season_id?: number
  priority?: ChecklistPriority
  state?: string
  search?: string
  sortBy?: string
  orderBy?: string
  page?: number
  per_page?: number
}) : Promise<{
  _metadata: {
    success: boolean,
    pagination: Pagination
  },
  result: {
    checklists: Checklist[]
  }
}> => {
  const filteredParams = Object.fromEntries(
    Object.entries(params)
      .filter(([_, value]) => value !== undefined)  // filter out undefined values
      .map(([key, value]) => [key, value.toString()]) // convert values to strings
  )
  const queryString = new URLSearchParams(filteredParams).toString()
  const response = await apiClient(`checklists?${queryString}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const createChecklist = async (params: {
  state_abbr: string
  county_id: number | null
  municipality_id: number | null
  city_contact_name: string | null
  city_contact_email: string | null
  city_contact_phone: string | null
  notes: string | null
  priority: ChecklistPriority
  requirements: ChecklistRequirements
  submission_type: ChecklistSubmissionType
  submission_email: string | null
  submission_url: string | null
}) : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    message: string
    checklist_id: number
  }
}> => {
  const response = await apiClient('checklists', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  })

  return response
}

export const updateChecklist = async (id: number, params: {
  state_abbr: string
  county_id: number | null
  municipality_id: number | null
  city_contact_name: string | null
  city_contact_email: string | null
  city_contact_phone: string | null
  notes: string | null
  priority: ChecklistPriority
  requirements: ChecklistRequirements
  submission_type: ChecklistSubmissionType
  submission_email: string | null
  submission_url: string | null
}) : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    message: string
  }
}> => {
  const response = await apiClient(`checklists/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  })

  return response
}

export const uploadChecklistAttachment = async (checklistId: number, file: File) : Promise<{
  _metadata: {
    success: boolean
  },
  results: {
    attachment: Attachment
  }
}> => {
  const formData = new FormData()
  formData.append('attachment', file)

  const response = await apiClient(`checklists/${checklistId}/attachments`, {
    method: 'POST',
    body: formData
  })

  return response
}

export const deleteChecklistAttachment = async (checklistId: number, attachmentId: number) : Promise<{
  _metadata: {
    success: boolean
  },
  results: {
    message: string
  }
}> => {
  const response = await apiClient(`checklists/${checklistId}/attachments/${attachmentId}`, {
    method: 'DELETE',
  })

  return response
}

export const deleteChecklist = async (checklistId: number): Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    message: string
  }
}> => {
  const response = await apiClient(`checklists/${checklistId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const fetchChecklist = async (checklistId: number): Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    checklist: Checklist
  }
}> => {
  const response = await apiClient(`checklists/${checklistId}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const fetchChecklistAttachments = async (checklistId: number): Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    attachments: Attachment[]
  }
}> => {
  const response = await apiClient(`checklists/${checklistId}/attachments`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const downloadChecklistAttachment = async (checklistId: number, attachmentId: number) : Promise<Blob> => {
  const response = await apiClient(`checklists/${checklistId}/attachments/${attachmentId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseMode: 'blob'
  })

  return response
}

export const previewChecklistAttachment = async (checklistId: number, attachmentId: number) : Promise<Blob> => {
  const response = await apiClient(`checklists/${checklistId}/attachments/${attachmentId}/preview`, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseMode: 'blob'
  })

  return response
}
