import { State } from 'interface'
import { apiClient } from './config'

export const fetchStates = async () : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    states: State[]
  }
}> => {
  const response = await apiClient('states', {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}
