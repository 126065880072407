import React from 'react'

interface IFileUploadProps {
  onFileUpload: (files: FileList) => void
  acceptedFileTypes: string[]
}

const FileUpload: React.FC<IFileUploadProps> = ({ onFileUpload, acceptedFileTypes }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onFileUpload(event.target.files)
      event.target.value = ''
    }
  }

  return (
    <input
      type="file"
      id="file-upload"
      className="hidden"
      onChange={handleFileChange}
      multiple
      accept={acceptedFileTypes.join(',')}
    />
  )
}

export default FileUpload
