import { Application, ApplicationDetails, Attachment, Pagination } from 'interface'
import { apiClient } from './config'

export const fetchApplication = async (applicationId: number) : Promise<{
  _metadata: {
    success: boolean
    pagination: Pagination
  },
  result: ApplicationDetails
}> => {
  const response = await apiClient(`license-applications/${applicationId}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const fetchApplications = async (params: {
  season_id?: number
  team_id?: number
  search?: string
  page?: number
  per_page?: number
}) : Promise<{
  _metadata: {
    success: boolean
    pagination: Pagination
  },
  result: {
    applications: Application[]
  }
}> => {
  const filteredParams = Object.fromEntries(
    Object.entries(params)
      .filter(([_, value]) => value !== undefined)  // filter out undefined values
      .map(([key, value]) => [key, value.toString()]) // convert values to strings
  )
  const queryString = new URLSearchParams(filteredParams).toString()
  const response = await apiClient(`license-applications?${queryString}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const createApplication = async (params: {
  checklist_id: number
  primary_sales_rep_id: number
  notes?: string
  additional_sales_rep_ids?: number[]
  registration_in_person?: boolean
  wet_signature?: boolean
  digital_signature?: boolean
  background_check?: boolean
  fingerprints?: boolean
  identification_requirements?: boolean
  photo_requirements?: boolean
  bond?: boolean
  certificate_of_insurance?: boolean
  articles_of_incorporation?: boolean
  business_license?: boolean
  certificate_of_authority?: boolean
  letter_of_authorization?: boolean
  personal_references?: boolean
  local_references?: boolean
  professional_references?: boolean
  sales_tax_id?: boolean
  vehicle_registration?: boolean
  vehicle_insurance?: boolean
  additional_appointments_required?: boolean
}) : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    message: string
    id: number
  }
}> => {
  const response = await apiClient('license-applications', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  })

  return response
}

export const updateApplication = async (applicationId: number, params: {
  primary_sales_rep_id: number
  notes?: string
  additional_sales_rep_ids?: number[]
  registration_in_person?: boolean
  wet_signature?: boolean
  digital_signature?: boolean
  background_check?: boolean
  fingerprints?: boolean
  identification_requirements?: boolean
  photo_requirements?: boolean
  bond?: boolean
  certificate_of_insurance?: boolean
  articles_of_incorporation?: boolean
  business_license?: boolean
  certificate_of_authority?: boolean
  letter_of_authorization?: boolean
  personal_references?: boolean
  local_references?: boolean
  professional_references?: boolean
  sales_tax_id?: boolean
  vehicle_registration?: boolean
  vehicle_insurance?: boolean
  additional_appointments_required?: boolean
}) : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    message: string
    id: number
  }
}> => {
  const response = await apiClient(`license-applications/${applicationId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  })

  return response
}

export const uploadApplicationAttachment = async (applicationId: number, file: File) : Promise<{
  _metadata: {
    success: boolean
  },
  results: {
    attachment: Attachment
  }
}> => {
  const formData = new FormData()
  formData.append('attachment', file)

  const response = await apiClient(`license-applications/${applicationId}/attachments`, {
    method: 'POST',
    body: formData
  })

  return response
}

export const fetchApplicationAttachments = async (applicationId: number): Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    attachments: Attachment[]
  }
}> => {
  const response = await apiClient(`license-applications/${applicationId}/attachments`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const deleteApplicationAttachment = async (applicationId: number, attachmentId: number) : Promise<{
  _metadata: {
    success: boolean
  },
  results: {
    message: string
  }
}> => {
  const response = await apiClient(`license-applications/${applicationId}/attachments/${attachmentId}`, {
    method: 'DELETE',
  })

  return response
}

export const downloadApplicationAttachment = async (applicationId: number, attachmentId: number) : Promise<Blob> => {
  const response = await apiClient(`license-applications/${applicationId}/attachments/${attachmentId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseMode: 'blob'
  })

  return response
}

export const previewApplicationAttachment = async (applicationId: number, attachmentId: number) : Promise<Blob> => {
  const response = await apiClient(`license-applications/${applicationId}/attachments/${attachmentId}/preview`, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseMode: 'blob'
  })

  return response
}

export const deleteApplication = async (applicationId: number): Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    message: string
  }
}> => {
  const response = await apiClient(`license-applications/${applicationId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}
