const CompanyInfoView: React.FC = () => {
  return (
    <>
      <div className="flex justify-between items-center h-[70px] px-6">
        <h1 className="text-[32px] leading-10">Company Info</h1>
      </div>
    </>
  )
}

export default CompanyInfoView
