var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import classNames from "classnames";
import { FilledIcons } from "../../shared/Icon";
import AptiveIcon from "../Icon";
import { useControlledState } from '@react-stately/utils';
import Button from "../Button/Button";
export var Toast = React.forwardRef(function (props, ref) {
    var id = props.id, className = props.className, children = props.children, _a = props.status, status = _a === void 0 ? 'info' : _a, dismissible = props.dismissible, onDismiss = props.onDismiss, _b = props.dismissed, dismissed = _b === void 0 ? false : _b, isIcon = props.isIcon, header = props.header, isActionButton = props.isActionButton, actionIcon = props.actionIcon, onClickActionButton = props.onClickActionButton, _c = props.actionButtonTitle, actionButtonTitle = _c === void 0 ? "Take action" : _c, avatar = props.avatar, other = __rest(props, ["id", "className", "children", "status", "dismissible", "onDismiss", "dismissed", "isIcon", "header", "isActionButton", "actionIcon", "onClickActionButton", "actionButtonTitle", "avatar"]);
    var toast = useContext(ThemeContext).theme.toast;
    var _d = useControlledState(dismissed, false, function (val) {
        return val && onDismiss ? onDismiss() : null;
    }), dismissedState = _d[0], setDismissedState = _d[1];
    var _status = "";
    avatar ? _status = "withAvatar" : _status = status;
    var baseStyle = toast.base;
    var statusStyle = toast[_status];
    var iconBaseStyle = toast.icon.base;
    var iconWithHeaderStyle = toast.icon.header;
    var iconColorStyle = toast.icon.color[_status];
    var iconBgStyle = toast.icon.bg[_status];
    var Icon;
    switch (_status) {
        case 'success':
            !header ? Icon = FilledIcons["check"] : Icon = FilledIcons["checkCircle"];
            break;
        case 'warning':
            !header ? Icon = FilledIcons["exclamation"] : Icon = FilledIcons["exclamation"];
            break;
        case 'danger':
            !header ? Icon = FilledIcons["xCircle"] : Icon = FilledIcons["xCircle"];
            break;
        case 'info':
            !header ? Icon = FilledIcons["informationCircle"] : Icon = FilledIcons["informationCircle"];
            break;
        default: Icon = FilledIcons["informationCircle"];
    }
    var cls = classNames(baseStyle, statusStyle, className);
    var iconCls = classNames(!header ? iconBaseStyle : iconWithHeaderStyle, iconColorStyle, !header ? iconBgStyle + ' mr-[10px]' : 'mr-[8px]');
    var closeCls = classNames(iconBaseStyle, iconColorStyle, 'ml-[12px]');
    var withHeaderStyle = toast.withHeaderStyle;
    var withHeaderCls = classNames(withHeaderStyle, toast.header[status]);
    var noHeaderStyle = toast.noHeaderStyle;
    var closeBtnStyle = toast.closeBtnStyle;
    var childrenBodyStyle = toast.childrenBodyStyle;
    var actionButtonStyle = toast.actionButtonStyle;
    var avatarHeaderStyle = toast.avatarHeaderStyle;
    var closeWithAvatarStyle = toast.icon.closeWithAvatar;
    if (dismissedState) {
        return null;
    }
    return (_jsx("div", __assign({ id: id, className: cls }, other, { ref: ref }, { children: avatar ?
            _jsxs("div", __assign({ className: "flex" }, { children: [avatar, _jsxs("div", __assign({ className: "ml-[12px]" }, { children: [_jsx("div", __assign({ className: avatarHeaderStyle }, { children: header })), _jsx("div", __assign({ className: noHeaderStyle }, { children: children })), isActionButton && _jsx("div", __assign({ className: actionButtonStyle }, { children: _jsx(Button, __assign({ size: "small", icon: actionIcon, isFilled: true, "aria-label": actionButtonTitle, type: "button", variant: "info", onClick: onClickActionButton }, { children: actionButtonTitle })) }))] })), dismissible && (_jsx("button", __assign({ onClick: function () { return setDismissedState(true); }, className: closeBtnStyle, "aria-label": "Close" }, { children: _jsx(AptiveIcon, { className: closeWithAvatarStyle, icon: "x", isFilled: true }) })))] }))
            : _jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex items-center" }, { children: [isIcon && _jsx(Icon, { className: iconCls }), header ? _jsx("div", __assign({ className: withHeaderCls, "aria-label": "Header" }, { children: header })) : _jsx("div", __assign({ className: noHeaderStyle }, { children: children })), dismissible && (_jsx("button", __assign({ onClick: function () { return setDismissedState(true); }, className: closeBtnStyle, "aria-label": "Close" }, { children: _jsx(AptiveIcon, { className: closeCls, icon: "x", isFilled: true }) })))] })), header &&
                        (_jsx("div", __assign({ className: childrenBodyStyle }, { children: _jsx("div", { children: children }) }))), isActionButton && _jsx("div", __assign({ className: actionButtonStyle }, { children: _jsx(Button, __assign({ size: "small", "aria-label": actionButtonTitle, type: "button", variant: status, onClick: onClickActionButton }, { children: actionButtonTitle })) }))] }) })));
});
