var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import classNames from 'classnames';
import { ThemeContext } from '../../context/ThemeContext';
var Tab = function (_a, ref) {
    var children = _a.children, value = _a.value, activeValue = _a.activeValue, onClick = _a.onClick, disabled = _a.disabled, className = _a.className, style = _a.style, props = __rest(_a, ["children", "value", "activeValue", "onClick", "disabled", "className", "style"]);
    var tab = useContext(ThemeContext).theme.tab;
    var contentStyle = tab.content;
    var baseStyle = tab.base;
    var activeStyle = tab.active;
    var disabledStyle = tab.disabled;
    var classes = classNames(contentStyle, className, disabled ? disabledStyle :
        value != null && value === activeValue ? activeStyle : baseStyle);
    return (_jsx("a", __assign({ role: "tab" }, props, { className: classes, style: style, onClick: function () { return !disabled && onClick && onClick(value); } }, { children: children })));
};
var TabInner = React.forwardRef(Tab);
export default TabInner;
