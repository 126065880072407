var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { OutlinedIcons, FilledIcons } from '../../shared/Icon';
export var AptiveIcon = function (_a) {
    var icon = _a.icon, id = _a.id, className = _a.className, _b = _a.isFilled, isFilled = _b === void 0 ? false : _b, props = __rest(_a, ["icon", "id", "className", "isFilled"]);
    var Icon = isFilled ? FilledIcons[icon] : OutlinedIcons[icon];
    return (_jsx(Icon, __assign({ id: id, className: className, label: icon }, props)));
};
