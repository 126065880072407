import moment from 'moment'
import { FC, useState, useEffect, useCallback, useMemo } from 'react'
import { Progress } from '@aptive-env/storybook'
import { formatDate } from 'lib/utils'
import { capitalize } from 'lodash'

import Filters from 'components/Filters'
import TableHeader from 'components/TableHeader'
import TableFooter from 'components/TableFooter'
import { AptiveIcon } from '@aptive-env/storybook'
import { fetchSeasons, fetchTeams, fetchApplications } from 'services'
import DeleteLicenseApplicationModal from '../DeleteLicenseApplication'
import { Application, Season, Team } from 'interface'

interface LicenseApplicationTableProps {
  version: number
  onClickNewApplication: () => void
  onClickEditApplication: (application: Application) => void
}

const LicenseApplicationTable: FC<LicenseApplicationTableProps> = ({
  version,
  onClickNewApplication,
  onClickEditApplication,
}) => {
  const [seasons, setSeasons] = useState<Season[]>([])
  const [teams, setTeams] = useState<Team[]>([])
  const [applications, setApplications] = useState<Application[]>([])
  const [isDeleteLicenseApplicationModalOpen, setIsDeleteLicenseApplicationModalOpen] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState<Application | null>(null)
  const [filter, setFilter] = useState<Record<string, string>>({})
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const filterOptions = useMemo(() => {
    return {
      team: { name: 'Team', items: teams.map((team) => team.name) },
      season: { name: 'Season', items: seasons.map((season) => season.name) },
      filters: { name: 'Filters', items: ['Mock Item 1', 'Mock Item 2', 'Mock Item 3'] },
      data: { name: 'Data', items: ['Mock Item 1', 'Mock Item 2', 'Mock Item 3'] }
    }
  }, [seasons, teams])

  const selectedSeason = useMemo(() => seasons.find((season) => season.name === filter.season), [filter.season, seasons])

  const selectedTeam = useMemo(() => teams.find((team) => team.name === filter.team), [filter.team, teams])

  const loadData = useCallback(async () => {
    if (selectedSeason) {
      setIsLoading(true)
      const response = await fetchApplications({
        season_id: selectedSeason.id,
        team_id: selectedTeam?.id ?? undefined,
        search: search || undefined,
        per_page: pageSize,
        page: currentPage,
      })

      setApplications(response.result.applications)
      setTotalCount(response._metadata.pagination.total)
      setIsLoading(false)
    } else {
      setApplications([])
      setTotalCount(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version, currentPage, pageSize, search, selectedSeason, selectedTeam])

  const handleDeleteApplication = (application: Application) => {
    setSelectedApplication(application)
    setIsDeleteLicenseApplicationModalOpen(true)
  }

  const handleHideShowApplication = (applicationId: number, isHidden: boolean) => {
    const tempApplications = [...applications]
    for (const application of tempApplications) {
      if (application.id === applicationId) {
        application.hidden = isHidden
      }
    }

    setApplications(tempApplications)
  }

  const handleEditApplication = (application: Application) => {
    onClickEditApplication(application)
  }

  const handleDownload = () => {
  }

  const fetchInitialValues = async () => {
    const [teamsResponse, seasonsResponse] = await Promise.all([
      fetchTeams(),
      fetchSeasons()
    ])

    setTeams(teamsResponse.result.teams)
    setSeasons(seasonsResponse.result.seasons)
    setFilter({
      season: seasonsResponse.result.seasons[0].name
    })
  }

  useEffect(() => {
    fetchInitialValues()
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <>
      <div className="flex justify-between items-center h-[70px] px-3">
        <h1 className="text-[32px] leading-10">License Applications</h1>
        <Filters
          values={filter}
          options={filterOptions}
          onChange={(value) => setFilter({ ...filter, ...value })}
          onDownload={handleDownload}
        />
      </div>
      <div className="flex flex-col flex-1 px-3 pb-3 overflow-hidden">
        <TableHeader
          search={search}
          setSearch={setSearch}
          addLabel="New Application"
          searchPlaceholder="Search applications"
          addNewModalOpen={onClickNewApplication}
        />
        <div className="flex flex-col overflow-hidden border-l border-r border-gray-200">
          <div className="flex flex-none gap-[4px] bg-gray-50 border-b border-gray-200">
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[74px]">
              App #
            </div>
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[100px]">
              <span>Status</span>
            </div>
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[100px] text-right">
              <span>ID</span>
            </div>
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[200px]">
              <span>Name</span>
            </div>
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[120px]">
              <span>State</span>
            </div>
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[120px]">
              <span>County</span>
            </div>
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[160px]">
              <span>Municipality</span>
            </div>
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[180px]">
              <span>Team</span>
            </div>
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[110px]">
              <span>Started</span>
            </div>
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[130px] pl-6">
              <span>Completion</span>
            </div>
            <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 pr-4 flex-1 text-right">
              <span>Actions</span>
            </div>
          </div>
          <div className="flex flex-col flex-auto overflow-x-hidden overflow-y-auto no-scrollbar">
            {isLoading && (
              <div className="flex w-full h-40 items-center justify-center">
                <span className="text-lg">Loading data...</span>
              </div>
            )}
            {!isLoading && applications.length === 0 && (
              <div className="flex w-full h-40 items-center justify-center">
                <span className="text-lg">No data</span>
              </div>
            )}
            {!isLoading && applications.length > 0 && applications.map((application) => (
              <div
                key={application.id}
                className={`flex gap-[4px] border-b border-gray-200 bg-white cursor-pointer last:border-none ${application.hidden ? 'hidden' : ''}`}
              >
                <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[74px]">
                  {application.id}
                </div>
                <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[80px]">
                  {capitalize(application.status)}
                </div>
                <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[100px] justify-end">
                  {application.primary_sales_rep?.id || ''}
                </div>
                <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[200px]">
                  {application.primary_sales_rep?.name || ''}
                </div>
                <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[120px]">
                  {application.state?.name || ''}
                </div>
                <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[120px]">
                  {application.county?.name || ''}
                </div>
                <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[160px]">
                  {application.municipality?.name || ''}
                </div>
                <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[180px]">
                  {application.team?.name || ''}
                </div>
                <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[120px]">
                  {moment(application.created_at).format(formatDate.FORMAT_DISPLAY)}
                </div>
                <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[130px]">
                  <Progress
                    className={
                      `!mt-0 !w-[100px] h-[20px] border border-[#E5E7EB] bg-[#F9FAFB]
                      [&>.progresslabel]:font-normal [&>.progresslabel]:text-xs [&>.progresslabel]:text-center [&>.progresslabel]:left-0 [&>.progresslabel]:top-[2px]
                      [&>.progressvalue]:h-[18px] ${application.completion_percentage >= 80 ? '[&>.progressvalue]:bg-[#31C48D]' : application.completion_percentage >= 50 ? '[&>.progressvalue]:bg-[#FACA15]' : '[&>.progressvalue]:bg-[#F05252]'}
                    `}
                    value={application.completion_percentage}
                  />
                </div>
                <div className="flex items-center justify-end gap-4 flex-1 px-4 py-5 text-right">
                  <AptiveIcon
                    className="w-[20px] h-[20px] fill-none stroke-[#9CA3AF] hover:stroke-[#1f2937]"
                    icon="pencilAlt"
                    onClick={() => handleEditApplication(application)}
                  />
                  <AptiveIcon
                    className="w-[20px] h-[20px] fill-none stroke-[#9CA3AF] hover:stroke-[#1f2937]"
                    icon="trash"
                    onClick={() => handleDeleteApplication(application)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <TableFooter
          page={currentPage}
          setPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalResults={totalCount}
        />
      </div>
      <DeleteLicenseApplicationModal
        isOpen={isDeleteLicenseApplicationModalOpen}
        onClose={() => setIsDeleteLicenseApplicationModalOpen(false)}
        onHideShow={handleHideShowApplication}
        application={selectedApplication}
        refresh={loadData}
      />
    </>
  )
}

export default LicenseApplicationTable
