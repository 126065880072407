import { create } from 'zustand'

type Toast = {
  type: 'success' | 'danger' | 'warning' | 'info'
  message: string
}

type State = {
  toast: Toast | null
}

type Actions = {
  setToast: (toast: Toast | null) => void
}

export const useToast = create<State & Actions>((set) => ({
  toast: null,
  setToast: (toast: Toast | null) => set(() => ({ toast })),
}))
