import { FC } from 'react'

const DetailNotes: FC = () => {
  return (
    <div className="flex h-full">
    </div>
  )
}

export default DetailNotes
