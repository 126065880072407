import { FC } from 'react'
import { AptiveIcon } from '@aptive-env/storybook'

interface ChecklistSelectModalHeaderProps {
  searchValue: string
  onClose: () => void
  onChangeSearchValue: (searchValue: string) => void
}

const ChecklistSelectModalHeader: FC<ChecklistSelectModalHeaderProps> = ({
  searchValue,
  onClose,
  onChangeSearchValue
}) => {
  return (
    <div className="w-full h-[60px] flex items-center justify-between border-b border-gray-200 pl-2 pr-4">
      <div className="flex items-center bg-white border border-[#E5E7EB] rounded-md h-[38px] w-[240px] overflow-hidden">
        <input
          className="px-3 w-full focus-visible:outline-none text-[#3d3d3d] text-sm placeholder-[#A9A9A9] border-none focus:border-none focus:ring-0"
          value={searchValue}
          onChange={(e) => onChangeSearchValue(e.target.value)}
          placeholder="Search requirements"
        />
        <div className="px-3 border-l h-full flex items-center">
          <AptiveIcon className="w-[20px] h-[20px] stroke-[#9CA3AF] fill-none" icon="search" />
        </div>
      </div>
      <div
        className="cursor-pointer"
        onClick={onClose}
      >
        <AptiveIcon
          className="w-4 h-4 fill-[#9CA3AF] stroke-none"
          icon="x"
          isFilled
        />
      </div>
    </div>
  )
}

export default ChecklistSelectModalHeader
