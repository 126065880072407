import React from 'react'
import { AptiveIcon } from '@aptive-env/storybook'
import { Attachment } from 'interface'


type FilePreviewProps = {
  files: (File | Attachment)[]
  onRemove?: (file: (File | Attachment)) => void
  onPreview?: (file: (File | Attachment)) => void
  onDownload?: (file: (File | Attachment)) => void
}

const FilePreview: React.FC<FilePreviewProps> = ({ 
  files, 
  onRemove,
  onPreview,
  onDownload
}) => {
  return (
    <>
      {files.map((file, index) => (
        <div key={index} className="flex justify-between items-center border-solid border bg-gray-50 border-gray-200 rounded-lg mt-4 h-[55px]">
          <div className="px-4 text-sm font-medium overflow-auto no-scrollbar">{(file as File).name ?? (file as Attachment).filename}</div>
          <div className="flex items-center px-4 gap-4 border-l h-full rounded-r-lg">
            {onPreview && ((file as Attachment).preview_url || (file as File).arrayBuffer) && (
              <AptiveIcon
                className="w-5 h-5 fill-none stroke-[#9CA3AF] m-auto cursor-pointer"
                icon="eye"
                onClick={() => onPreview(file)}
              />
            )}
            {onDownload && ((file as Attachment).source_url || (file as File).arrayBuffer) && (
              <AptiveIcon
                className="w-5 h-5 fill-none stroke-[#9CA3AF] m-auto cursor-pointer"
                icon="download"
                onClick={() => onDownload(file)}
              />
            )}
            {onRemove && (
              <AptiveIcon
                className="w-5 h-5 fill-none stroke-[#9CA3AF] m-auto cursor-pointer"
                icon="trash"
                onClick={() => onRemove(file)}
              />
            )}
          </div>
        </div>
      ))}
    </>
  )
}

export default FilePreview
