import { ChangeEvent, FocusEvent, useMemo } from 'react'
import classNames from 'classnames'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'

function formatPhoneNumber(value: string) {
  const digits = value.replace(/\D/g, '')
  if (digits.length <= 3) {
    return digits
  }
  if (digits.length <= 6) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3)}`
  }
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`
}

interface CustomPhoneProps<UseFormRegisterValues extends FieldValues> {
  id: string
  name: string
  disabled?: boolean
  formValue?: string
  value?: string
  hasError?: boolean
  className?: string
  register?: UseFormRegister<UseFormRegisterValues>
  childOrientation?: 'left' | 'right'
  ariaDescribedBy?: string
  placeholder?: string
  autoComplete?: string
  baseClasses?: {
    standardClasses: string
    errorClasses: string
  }
  children?: React.ReactNode
  required?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

const CustomPhone = <UseFormRegisterValues extends FieldValues>({
  id,
  name,
  disabled,
  formValue,
  value,
  hasError,
  className,
  register,
  childOrientation,
  ariaDescribedBy,
  placeholder,
  autoComplete,
  baseClasses,
  children,
  required,
  onBlur,
  onChange,
}: CustomPhoneProps<UseFormRegisterValues>) => {
  const classes = useMemo(
    () =>
      classNames(
        className,
        'shadow-sm block w-full sm:text-sm rounded-md [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield]',
        `${
          hasError && baseClasses
            ? baseClasses?.errorClasses
            : baseClasses?.standardClasses
        }`
      ),
    [hasError, className, baseClasses]
  )

  const registerdInputProps = useMemo(() => {
    return register?.(name as Path<UseFormRegisterValues>)
  }, [name, register])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const digits = event.target.value.replace(/\D/g, '')
    const phone = formatPhoneNumber(digits)
    const newEvent = {
      ...event,
      target: {
        ...event.target,
        name,
        value: phone
      },
    }
    registerdInputProps?.onChange(newEvent)
    onChange?.(newEvent)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur?.(event)
  }

  return (
    <div className="flex">
      {childOrientation === 'left' && children}
      <input
        {...(registerdInputProps && { ...registerdInputProps })}
        id={id}
        name={name}
        type="text"
        value={register ? formValue : value}
        className={classes}
        disabled={disabled}
        placeholder={placeholder}
        aria-describedby={ariaDescribedBy}
        aria-invalid={hasError}
        autoComplete={autoComplete}
        required={required}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {childOrientation === 'right' && children}
    </div>
  )
}

export default CustomPhone
