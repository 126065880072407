import React from 'react'
import classNames from 'classnames'
import { iconMap } from '.'

interface IIconProps {
  icon: string;
  id?: string;
  title?: string;
  className?: string;
  onClick?: () => void;
  message: string;
  place?: 'top' | 'bottom' | 'left' | 'right';
}

const Icon: React.FC<IIconProps> = ({
  icon,
  id = '',
  title = '',
  className = '',
  onClick,
}) => {
  const classes = classNames(className, 'flex items-center')
  return (
    <span data-for={id} className={classes} onClick={onClick}>
      <title>{title}</title>
      {iconMap[icon]}
    </span>
  )
}

export default Icon
