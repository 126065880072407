var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import AptiveIcon from '../Icon';
import { isNumber } from '../../helpers/common';
export var Chip = React.forwardRef(function (props, ref) {
    var id = props.id, className = props.className, children = props.children, icon = props.icon, isFilled = props.isFilled, disabled = props.disabled, active = props.active, _a = props.position, position = _a === void 0 ? "left" : _a, other = __rest(props, ["id", "className", "children", "icon", "isFilled", "disabled", "active", "position"]);
    var chip = useContext(ThemeContext).theme.chip;
    var baseStyle = chip.base;
    var sizeChipStyles = !icon && isNumber(children) ? { default: chip.circle } : { default: chip.size.default };
    var iconType = isFilled ? "filled" : "outlined";
    var defaultStyle = chip.color.default.content + " " + chip.color.default.icon[iconType];
    var activeStyle = chip.color.active.content + " " + chip.color.active.icon[iconType];
    var disabledStyle = chip.color.disabled.content + " " + chip.color.disabled.icon[iconType];
    var colorStyles = classNames(disabled ? disabledStyle :
        !disabled && active ? activeStyle : defaultStyle);
    var iconStyle = chip.icon.default.size;
    var iconLeftStyles = classNames(iconStyle, children ? chip.icon.default.space.left : '');
    var iconRightStyles = classNames(iconStyle, children ? chip.icon.default.space.right : '');
    var cls = classNames(baseStyle, sizeChipStyles.default, colorStyles, className);
    return (_jsxs("div", __assign({ id: id, className: cls }, other, ref, { children: [icon && position === 'left' ?
                _jsx(AptiveIcon, { className: iconLeftStyles, icon: icon, isFilled: isFilled }) : null, children, icon && position === 'right' ?
                _jsx(AptiveIcon, { className: iconRightStyles, icon: icon, isFilled: isFilled }) : null] })));
});
