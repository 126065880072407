var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { useId } from '../../hooks';
var Radio = React.forwardRef(function (props, ref) {
    var id = props.id, className = props.className, _a = props.checked, checked = _a === void 0 ? false : _a, onChange = props.onChange, children = props.children, _b = props.disabled, disabled = _b === void 0 ? false : _b, title = props.title, ariaLabel = props["aria-label"], _c = props.position, position = _c === void 0 ? "left" : _c, others = __rest(props, ["id", "className", "checked", "onChange", "children", "disabled", "title", 'aria-label', "position"]);
    var radio = useContext(ThemeContext).theme.radio;
    var tempInputId = useId('input_');
    var layoutStyle = radio.content;
    var baseStyle = radio.base;
    var vaildStyle = radio.vaild;
    var activeStyle = radio.active;
    var disabledStyle = radio.disabled;
    var cls = classNames(layoutStyle, !disabled && (vaildStyle + activeStyle), disabled && disabledStyle, className);
    var handleRadioChange = function (event) {
        onChange === null || onChange === void 0 ? void 0 : onChange(event.target.checked, event);
    };
    var inputId = id ? "input_".concat(id) : tempInputId;
    var labelStyles = classNames(children && position === "left" ? radio.space.left : radio.space.right);
    return (_jsxs("div", __assign({ className: cls }, others, { id: id }, { children: [children && position === "right" ? _jsx("label", __assign({ className: labelStyles, "aria-label": "Radio Label", htmlFor: inputId }, { children: children })) : null, _jsx("input", { type: "radio", ref: ref, className: baseStyle, checked: checked, title: title, onChange: handleRadioChange, "aria-label": ariaLabel, id: inputId, disabled: disabled }), children && position === "left" ? _jsx("label", __assign({ className: labelStyles, "aria-label": "Radio Label", htmlFor: inputId }, { children: children })) : null] })));
});
export default Radio;
