var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
var Breadcrumbs = React.forwardRef(function (_a, ref) {
    var breadcrumbs = _a.breadcrumbs;
    var breadcrumb = useContext(ThemeContext).theme.breadcrumb;
    var cls = breadcrumb.content;
    var listStyle = breadcrumb.base;
    var currentListStyle = breadcrumb.current;
    var slashStyle = breadcrumb.slash;
    return (_jsx("nav", __assign({ className: "breadcrumb" }, { children: _jsx("ol", __assign({ className: cls, ref: ref }, { children: breadcrumbs.map(function (item, index) { return (_jsxs("li", { children: [index === breadcrumbs.length - 1 ?
                        _jsx("a", __assign({ href: item.href, className: listStyle }, { children: item.label }))
                        : _jsx("a", __assign({ href: item.href, className: currentListStyle }, { children: item.label })), index !== breadcrumbs.length - 1 && (_jsx("span", __assign({ className: slashStyle }, { children: "/" })))] }, index)); }) })) })));
});
export default Breadcrumbs;
