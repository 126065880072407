import { FC, useState } from 'react'
import { Pagination } from '@aptive-env/storybook'

import styles from './index.module.scss'

interface TableFooterProps {
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  totalResults: number;
}

const TableFooter: FC<TableFooterProps> = ({ page, setPage, pageSize, setPageSize, totalResults }) => {
  const [skipOnPageChange, setSkipOnPageChange] = useState(false)

  return (
    <div className={`flex px-4 py-3 bg-white rounded-b-lg border border-gray-200 ${styles.paginationWrapper}`}>
      <Pagination
        currentPage={page}
        totalResults={totalResults}
        perPage={pageSize}
        pageSizeOptions={[10, 20, 30]}
        onPerPageChange={(value) => {
          if (value !== pageSize) {
            setPageSize(value)
            setSkipOnPageChange(true)
            setPage(1)
          }
        }}
        onPageChange={(activePage) => {
          if (skipOnPageChange) {
            setSkipOnPageChange(false)
          } else {
            setPage(activePage)
          }
        }}
      />
    </div>
  )
}

export default TableFooter
