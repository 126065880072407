import { FC } from 'react'
import { AptiveIcon, Button } from '@aptive-env/storybook'
import { Checklist } from 'interface'
import { capitalize } from 'lodash'

interface ChecklistSelectModalTableProps {
  checklists: Checklist[]
  onSelectChecklist: (checklist: Checklist) => void
}

const ChecklistSelectModalTable: FC<ChecklistSelectModalTableProps> = ({
  checklists,
  onSelectChecklist
}) => {
  return (
    <div className="flex flex-col grow overflow-hidden pb-[50px]">
      <div className="flex flex-none gap-[4px] bg-gray-50 border-b border-gray-200">
        <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[160px]">
          <span>State</span>
        </div>
        <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[160px]">
          <span>Counties</span>
        </div>
        <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[160px]">
          <span>Municipalities</span>
        </div>
        <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 flex-none w-[120px]">
          <span>Priorities</span>
        </div>
        <div className="text-xs text-gray-600 uppercase tracking-[0.03em] p-3 pr-4 flex-1 text-right">
          <span>Actions</span>
        </div>
      </div>
      <div className="flex flex-col flex-auto overflow-x-hidden overflow-y-auto max-h-[500px]">
        {checklists.map((checklist) => (
          <div key={checklist.id} className="flex gap-[4px] border-b border-gray-200 bg-white cursor-pointer">
            <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[160px]">
              {checklist.state?.name}
            </div>
            <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[160px]">
              {checklist.county?.name}
            </div>
            <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[160px]">
              {checklist.municipality?.name}
            </div>
            <div className="flex items-center text-sm text-gray-600 p-3 flex-none w-[120px]">
              {checklist.priority === 'high' && (
                <AptiveIcon
                  className="w-[16px] h-[16px] stroke-[#F05252] fill-none ml-[-10px]"
                  icon="chevronUp"
                />
              )}
              {checklist.priority === 'medium' && (
                <AptiveIcon
                  className="w-[16px] h-[16px] stroke-[#F59E0B] fill-none ml-[-10px]"
                  icon="menuAlt4"
                />
              )}
              {checklist.priority === 'low' && (
                <AptiveIcon
                  className="w-[16px] h-[16px] stroke-[#0EA5E9] fill-none ml-[-10px]"
                  icon="chevronDown"
                />
              )}
              <span className="pl-3">{capitalize(checklist.priority)}</span>
            </div>
            <div className="flex items-center text-sm text-gray-600 p-3 pr-4 flex-1 justify-end">
              <Button
                variant="default"
                onClick={() => onSelectChecklist(checklist)}
              >
                Select
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ChecklistSelectModalTable
