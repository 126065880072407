import { Season } from 'interface'
import { apiClient } from './config'

export const fetchSeasons = async () : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    seasons: Season[]
  }
}> => {
  const response = await apiClient('seasons', {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}
