import { FC } from 'react'
import classNames from 'classnames'

interface ICustomTextAreaProps {
  formValue: string;
  id: string;
  hasError: boolean;
  rows: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  ariaDescribedBy?: string;
  name: string;
  baseClasses: {
    errorClasses: string;
    standardClasses: string;
  };
  register?: any;
  placeholder?: string;
}

const CustomTextArea: FC<ICustomTextAreaProps> = ({
  formValue,
  id,
  hasError,
  rows,
  onChange,
  onBlur,
  required,
  disabled,
  className,
  ariaDescribedBy,
  name,
  baseClasses,
  register,
  placeholder,
}) => {
  const { errorClasses, standardClasses } = baseClasses
  const classes = classNames(
    className,
    'shadow-sm block w-full sm:text-sm border rounded-md',
    `${hasError ? errorClasses : standardClasses}`
  )
  return (
    <>
      <textarea
        {...(register && { ...register(name) })}
        value={formValue}
        id={id}
        name={name}
        className={classes}
        rows={rows}
        onChange={onChange}
        {...onBlur && { onBlur }}
        required={required}
        disabled={disabled}
        aria-describedby={ariaDescribedBy}
        aria-invalid={hasError}
        placeholder={placeholder}
      />
    </>
  )
}

export default CustomTextArea
