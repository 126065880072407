import { Avatar, AptiveIcon } from '@aptive-env/storybook'
import { differenceInYears } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import { SalesRepresentative } from 'interface/salesRepresentative'

interface DetailHeaderProps {
  profile: SalesRepresentative | null;
  onClose: () => void;
}

const DetailHeader: React.FC<DetailHeaderProps> = ({ profile, onClose }) => {
  const getProfileAddress = () => {
    if (profile?.address)
      return profile.address === 'n/a' ? 'N/A' : profile.address
    return 'N/A'
  }

  return (
    <div className="flex flex-col relative px-6 pt-6 pb-4 bg-gray-100 border-b border-gray-200">
      <div className="flex flex-col pr-8">
        <div className="flex items-center gap-4">
          <Avatar
            imgSrc={profile?.avatar_url}
            label="User"
            size="3xl"
            aria-haspopup="true"
            aria-label="User Avatar"
          />
          <div className="flex flex-col gap-1.5">
            <div className="flex items-center">
              <p className="text-base leading-5 text-gray-900 font-semibold mr-3">{profile?.name}</p>
              <AptiveIcon
                className="w-[16px] h-[16px] stroke-[#0E9F6E] fill-none mr-1"
                icon="checkCircle"
              />
              <span className="text-xs text-green-500">{profile?.status}</span>
            </div>
            <div className="flex items-center gap-1">
              <span className="text-xs text-gray-600">Sales Rep</span>
              <span className="text-xs text-gray-600">•</span>
              <span className="text-xs text-gray-600">
                Age {profile?.date_of_birth ? differenceInYears(Date.now(), profile.date_of_birth) : ''}
              </span>
              <span className="text-xs text-gray-600">•</span>
              <span className="text-xs text-gray-600">
                {getProfileAddress()}
              </span>
            </div>
          </div>
        </div>
        <a 
          className="text-sm text-gray-900 ml-20 mt-1.5 w-fit"
          href="/"
        >
          View full profile
        </a>
        <div className="flex items-center gap-4 mt-4">
          <div className="flex items-center gap-1">
            <AptiveIcon
              className="w-[24px] h-[24px] stroke-[#9CA3AF] fill-none"
              icon="calendar"
            />
            <span className="text-xs text-gray-600">
              Joined {profile?.date_of_hire ? formatInTimeZone(profile.date_of_hire, Intl.DateTimeFormat().resolvedOptions().timeZone, 'MMM yyyy') : ''}
            </span>
          </div>
          <div className="flex items-center gap-1">
            <AptiveIcon
              className="w-[24px] h-[24px] stroke-[#9CA3AF] fill-none"
              icon="userGroup"
            />
            <span className="text-xs text-gray-600">
              {profile?.team?.name || 'N/A'}
            </span>
          </div>
        </div>
      </div>
      <AptiveIcon
        className="absolute top-4 right-5 w-[24px] h-[24px] stroke-[#9CA3AF] fill-none cursor-pointer"
        icon="x"
        onClick={onClose}
      />
    </div>
  )
}

export default DetailHeader
