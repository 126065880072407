import {FC} from 'react'
import { CustomButtonGroup } from 'components/common'

interface ChecklistFormFooterProps {
  onCancel: () => void;
  onSubmit: () => void;
  isValid?: boolean;
  isSubmitting?: boolean;
}

const ChecklistFormFooter: FC<ChecklistFormFooterProps> = ({
  onCancel,
  onSubmit,
  isValid,
  isSubmitting
}) => {
  return (
    <div className="w-full h-16 flex justify-end items-center border-t border-gray-200 px-6">
      <CustomButtonGroup
        onCancelClick={onCancel}
        onSaveClick={onSubmit}
        disabledCancel={false}
        disabledSave={!isValid || isSubmitting}
        cancelText="Cancel"
        saveText="Submit"
        buttonClassName="w-20"
        wrapperClassName="flex justify-end w-full"
        orientation="right"
        loading={false}
        withSubmit={true}
      />
    </div>
  )
}

export default ChecklistFormFooter
