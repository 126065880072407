import { ChangeEvent, useMemo, useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { Button, Dropdown } from '@aptive-env/storybook'
import { useOnClickOutside } from 'hooks'
import { AptiveIcon } from '@aptive-env/storybook'

interface SelectOption {
  name: string;
  value: string | number;
  icon?: string,
  iconClassName?: string
}

interface ICustomSelectProps {
  id: string;
  name: string;
  formValue?: string | number;
  value?: string |number;
  register?: any;
  watch?: any
  autoComplete?: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  selectOptions: SelectOption[];
  ariaDescribedBy?: string;
  hasError?: boolean;
  baseClasses?: {
    errorClasses: string;
    standardClasses: string;
  };
  disabled?: boolean;
  onBlur?: () => void;
  dropdownPosition?: 'left' | 'right';
  searchable?: boolean;
}

const CustomSelect: React.FC<ICustomSelectProps> = ({
  id,
  formValue,
  value,
  register,
  watch,
  name,
  className,
  onChange,
  selectOptions,
  ariaDescribedBy,
  hasError,
  baseClasses,
  disabled,
  onBlur,
  dropdownPosition = 'right',
  searchable
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [selectOption, setSelectOption] = useState<SelectOption | null>(selectOptions.find(option => option.value === formValue) || null)
  const [searchQuery, setSearchQuery] = useState('')

  const ref = useRef<HTMLDivElement>(null)

  const classes = useMemo(() => classNames(
    className,
    'shadow-sm block w-full sm:text-sm rounded-md disabled:opacity-100 disabled:select-text cursor-default flex justify-between',
    `${ hasError ? baseClasses?.errorClasses : baseClasses?.standardClasses }`,
    { 'text-gray-400': disabled },
  ),
  [baseClasses?.errorClasses, baseClasses?.standardClasses, className, disabled, hasError]
  )

  const handleOnClickOutside = () => {
    setIsDropdownOpen(false)
  }

  useOnClickOutside(ref, handleOnClickOutside)

  const handleChange = (value: string | number) => {
    const selected  = selectOptions.find(option => option.value === value) || null
    setSelectOption(selected)
    onChange && onChange({ target: { name, value } } as ChangeEvent<HTMLSelectElement>)
    setIsDropdownOpen(false)
  }

  useEffect(() => {
    if (watch) {
      const selected = selectOptions.find(option => option.value === watch(name)) || null
      setSelectOption(selected)
    }
  }, [watch, name, selectOptions])

  return (
    <div className={isDropdownOpen ? 'relative' : '' } ref={ref}>
      <Button
        aria-haspopup="true"
        aria-label="Dropdown button"
        icon="chevronDown"
        position="right"
        isFilled={false}
        variant="neutral"
        className={classes}
        onClick={() => setIsDropdownOpen((prev) => !prev)}
        disabled={disabled}
        onBlur={onBlur}
      >
        {selectOption?.icon ? (
          <div className="flex gap-4 items-center">
            <AptiveIcon icon={selectOption.icon} className={selectOption.iconClassName} isFilled />
            <span>{selectOption.name}</span>
          </div>
        ) : (
          selectOption?.name || <span className="text-gray-400 font-normal">Select</span>
        )}
        <input
          id={id}
          type="hidden"
          name={name}
          value={register ? formValue : value}
          {...(register && { ...register(name) })}
          aria-describedby={ariaDescribedBy}
          onChange={onChange}
        />
      </Button>
      <Dropdown
        className={classNames('w-full z-10 max-h-[250px] overflow-y-auto no-scrollbar', {'right-0': dropdownPosition === 'left'})}
        isOpen={isDropdownOpen}
        onClose={() => {}}
      >
        {searchable && (
          <div className='grid grid-cols-6 items-center p-2'>
            <AptiveIcon className="sm:col-span-1 w-4 h-4 ml-2 stroke-[#9CA3AF] fill-none" icon='search'/>
            <input
              type="text"
              placeholder="Search"
              className="sm:col-span-5 w-full h-[32px] rounded border-gray-200 border p-2 text-sm focus:outline-none focus:ring-1 focus:ring-primary focus:ring-opacity-50"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>)}
        {selectOptions &&
          selectOptions.filter(option => option.name?.toLowerCase().includes(searchQuery.toLowerCase())).map(({ name: label, value, icon, iconClassName }, index) => {
            return (
              <div
                key={index}
                onClick={() => handleChange(value)}
                className={classNames('pl-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-700 text-sm')}
              >
                {icon ? <div className='flex gap-4 items-center'><AptiveIcon icon={icon} className={iconClassName} isFilled /><span>{label}</span></div> : label }
              </div>
            )
          })}
      </Dropdown>
    </div>
  )
}

export default CustomSelect
