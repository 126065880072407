import { BrowserRouter as Router, Routes } from 'react-router-dom'
import { renderSiteMap, mainLayoutRoute } from 'routes'

function App() {
  return (
    <Router>
      <Routes>{renderSiteMap({ mainLayoutRoute })}</Routes>
    </Router>
  )
}

export default App
