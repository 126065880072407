var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
var RecentPage = React.forwardRef(function (_a, ref) {
    var className = _a.className, _b = _a.paths, paths = _b === void 0 ? [] : _b, _c = _a.title, title = _c === void 0 ? "recent pages" : _c, children = _a.children, props = __rest(_a, ["className", "paths", "title", "children"]);
    var menu = useContext(ThemeContext).theme.menu;
    var cls = classNames(className, menu.recent.content);
    var titleCls = menu.recent.title;
    var linkClass = menu.recent.link;
    return (_jsxs("ul", __assign({}, props, { className: cls, ref: ref }, { children: [_jsx("li", __assign({ className: titleCls }, { children: title })), paths === null || paths === void 0 ? void 0 : paths.map(function (item, index) {
                return _jsx("li", __assign({ className: linkClass }, { children: _jsx("a", __assign({ href: item.path || '#' }, { children: item.title })) }), index);
            })] })));
});
export default RecentPage;
