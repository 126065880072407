import { Modal } from '@aptive-env/storybook'

import LicenseForm from './LicenseForm'
import { 
  Attachment,
  License, 
  SalesRepresentative, 
  State, 
} from 'interface'

interface ILicenseModalProps {
  isOpen: boolean;
  license: License | null;
  salesRepresentative: SalesRepresentative | null;
  setSalesRepresentativeLicenses: React.Dispatch<React.SetStateAction<License[]>>;
  onClose: () => void;
  states:  State[];
  selectedLicenseAttachments: Attachment[];
}

const LicenseModal: React.FC<ILicenseModalProps> = ({ 
  isOpen, 
  license, 
  salesRepresentative,
  setSalesRepresentativeLicenses,
  onClose,
  states,
  selectedLicenseAttachments
}) => {
  return (
    <Modal open={isOpen} onClickOutside={onClose} >
      <LicenseForm
        license={license}
        salesRepresentative={salesRepresentative}
        setSalesRepresentativeLicenses={setSalesRepresentativeLicenses}
        selectedLicenseAttachments={selectedLicenseAttachments}
        onClose={onClose}
        states={states}
      />
    </Modal>
  )
}

export default LicenseModal
