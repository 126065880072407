import { mergeClassName } from 'lib/utils'
import { Loading } from '@aptive-env/storybook'
interface ILoaderProps {
  className?: string | string[] | object;
}

const Loader: React.FC<ILoaderProps> = ({ className }) => (
  <div className={mergeClassName('flex justify-center', className)}>
    <Loading className='loading'/>
  </div>
)
  
export default Loader
