import classNames from 'classnames'
import { AptiveIcon } from '@aptive-env/storybook'

interface IFormLabelProps {
  label?: string;
  errors?: string[];
  htmlFor: string;
  required: boolean;
  errorClassName?: string;
  successClassName?: string;
  labelClassName?: string;
  isSuccess?: boolean;
}

const FormLabel: React.FC<IFormLabelProps> = ({
  label = '',
  errors = [],
  htmlFor,
  required = false,
  errorClassName = '',
  successClassName = '',
  labelClassName = '',
  isSuccess,
}) => {
  const labelClasses = classNames(
    labelClassName,
    'flex text-sm font-medium text-gray-700'
  )
  const successClasses = classNames(
    successClassName,
    'flex text-sm font-medium text-green-700'
  )
  const errorClasses = classNames(
    errorClassName,
    'flex mt-2 text-sm font-medium text-red-600'
  )
  return (
    <>
      {(label.length > 0 || errors.length > 0) &&
        (errors[0]?.length > 0 ? (
          <div className={errorClasses}>
            <span>
              <AptiveIcon
                className="w-5 h-5 stroke-red-600 fill-none"
                icon="exclamationCircle"
              />
            </span>
            <label htmlFor={htmlFor}>{errors[0]}</label>
          </div>
        ) : (
          <div className={isSuccess ? successClasses : labelClasses}>
            <label htmlFor={htmlFor} className="flex flex-inline gap-1">
              <div>{label}</div>
              {required ? <div className=" text-red-500">*</div> : null}
            </label>
          </div>
        ))}
    </>
  )
}

export default FormLabel
