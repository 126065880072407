import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export const mergeClassName = (...classes: any[]) => {
  return twMerge(classNames(...classes))
}

export const generateBaseClasses = (type: string = 'text', baseWrapperClassName: string = '') => {

  const placeholderTextColor: PlaceholderTextColorProps = {
    text: '300',
    date: '900',
  }

  interface PlaceholderTextColorProps {
    text: string;
    date: string;
    [key: string]: string;
  }

  const standardClasses = classNames(
    'focus:ring-blue-500 focus:outline-none focus:border-blue-500',
    'bg-white px-3 py-2',
    'border border-gray-300',
    `placeholder-gray-${placeholderTextColor[type] ?? '300'}`,
    baseWrapperClassName,
  )

  const errorClasses = classNames(
    'focus:ring-red-700 focus:outline-none focus:border-red-700',
    'border border-red-500',
    'bg-pink-100 px-3 py-2',
    'placeholder-red-300',
    'focus:outline-none',
    baseWrapperClassName,
  )

  return {
    standardClasses,
    errorClasses,
  }
}
