import { useMemo } from 'react'
import classNames from 'classnames'

interface ICustomNumberProps {
  id: string
  name: string
  disabled?: boolean
  formValue?: string
  value?: string
  hasError?: boolean
  min?: number
  max?: number
  className?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  register?: any
  childOrientation?: 'left' | 'right'
  ariaDescribedBy?: string
  placeholder?: string
  autoComplete?: string
  baseClasses?: {
    standardClasses: string
    errorClasses: string
  }
  children?: React.ReactNode
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  step?: number
  required?: boolean
}

const CustomNumber: React.FC<ICustomNumberProps> = ({
  id,
  name,
  disabled,
  formValue,
  value,
  hasError,
  min,
  max,
  className,
  onChange,
  register,
  childOrientation,
  ariaDescribedBy,
  placeholder,
  autoComplete,
  baseClasses,
  children,
  onBlur,
  step,
  required,
}) => {
  const classes = useMemo(
    () =>
      classNames(
        className,
        'shadow-sm block w-full sm:text-sm rounded-md [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield]',
        `${
          hasError && baseClasses
            ? baseClasses?.errorClasses
            : baseClasses?.standardClasses
        }`
      ),
    [hasError, className, baseClasses]
  )

  const handleWheel = (event: { target: { blur: () => void; focus: () => void } }) => {
    event.target.blur()
    setTimeout(() => event.target.focus(), 0)
  }

  return (
    <div className="flex">
      {childOrientation === 'left' ? children : null}
      <input
        {...(register && { ...register(name) })}
        id={id}
        min={min}
        max={max}
        name={name}
        type={'number'}
        value={register ? formValue : value}
        className={classes}
        {...(onChange && { ...{ onChange } })}
        disabled={disabled}
        placeholder={placeholder}
        aria-describedby={ariaDescribedBy}
        aria-invalid={hasError}
        autoComplete={autoComplete}
        {...(onBlur && { onBlur })}
        step={step || 0.01}
        required={required}
        onWheel={handleWheel}
      />
      {childOrientation === 'right' ? children : null}
    </div>
  )
}

export default CustomNumber
