import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Icon, Loader } from '..'
import {
  FormLabel,
  CustomInput,
  CustomErrorMessage,
  CustomSelect,
  CustomFile,
  CustomNumber,
  CustomTextArea,
  CustomDate,
  CustomPhone,
} from '.'
import { Select } from 'components/common'
import { generateBaseClasses } from 'lib'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { Attachment } from 'interface'

export interface ICustomFormElementProps<UseFormRegisterValues extends FieldValues> {
  id: string;
  type: string;
  label?: string;
  error?: { message: string };
  loading?: boolean;
  required?: boolean;
  isFileLoading?: boolean;
  tooltipMessage?: string;
  formElementWrapperClassName?: string;
  elementWrapperClassName?: string;
  baseWrapperClassName?: string;
  name?: string;
  selectOptions?: any[];
  onChange?: (event: React.ChangeEvent<any>) => void;
  register?: UseFormRegister<UseFormRegisterValues>;
  watch?: any;
  disabled?: boolean;
  className?: string;
  isMulti?: boolean;
  options?: any[];
  placeholder?: string;
  rows?: number;
  value?: any;
  attachmentLabel?: string;
  attachmentDescription?: string;
  dropdownPosition?: 'left' | 'right';
  searchable?: boolean;
  mark?: string;
  acceptedFileTypes?: string[];
  maxFileSize?: number;
  maxFileCount?: number;
  hasCopyButton?: boolean;
  defaultFiles?: (File | Attachment)[];
  onPreviewFile?: (file: File | Attachment) => void;
  onDownloadFile?: (file: File | Attachment) => void;
}

const CustomFormElement = <UseFormRegisterValues extends FieldValues>(props: ICustomFormElementProps<UseFormRegisterValues>) => {
  const {
    id,
    type,
    label,
    error,
    loading,
    required = false,
    isFileLoading,
    tooltipMessage,
    formElementWrapperClassName,
    elementWrapperClassName,
    baseWrapperClassName,
  } = props

  const elementWrapperClasses = useMemo(
    () =>
      classNames(
        'mt-0.5 block rounded-md',
        {'shadow-sm': type !== 'checkbox'},
        elementWrapperClassName,
      ),
    [elementWrapperClassName, type]
  )

  const baseClasses = useMemo(
    () => generateBaseClasses(type, baseWrapperClassName),
    [type, baseWrapperClassName]
  )

  const ChildElement: any = useMemo(() => {
    return {
      textArea: CustomTextArea,
      file: CustomFile,
      text: CustomInput,
      number: CustomNumber,
      phone: CustomPhone<UseFormRegisterValues>,
      select: CustomSelect,
      multiSelect: Select,
      date: CustomDate,
    }[type] || CustomInput
  }, [type])

  return (
    <div className={formElementWrapperClassName}>
      <div className="flex items-center">
        {(label?.length ?? 0) > 0 && (
          <FormLabel 
            label={label} 
            required={required} 
            htmlFor={id} 
          />
        )}
        {tooltipMessage && (
          <div className="ml-1">
            <Icon id={id} place="top" icon="info" message={tooltipMessage} />
          </div>
        )}
      </div>
      {loading ? (
        <Loader className="mt-0.5 h-[38px] items-center justify-start px-3" />
      ) : (
        <div className={elementWrapperClasses}>
          <ChildElement
            baseClasses={baseClasses}
            hasError={!!error}
            {...props}
          />
        </div>
      )}
      {error?.message && !isFileLoading && (
        <CustomErrorMessage text={error?.message} />
      )}
    </div>
  )
}

export default CustomFormElement
