import {FC} from 'react'
import { CustomButtonGroup } from 'components/common'

interface ILicenseFooterProps {
  onCancel: () => void;
  onSave: () => void;
  isValid?: boolean;
  isDirty?: boolean;
  isSubmitting?: boolean;
}

const LicenseFooter: FC<ILicenseFooterProps> = ({
  onCancel,
  onSave,
  isValid,
  isDirty,
  isSubmitting,
}) => {
  return (
    <div className="w-full h-16 flex justify-end items-center border-t border-gray-200 px-6">
      <CustomButtonGroup
        onCancelClick={onCancel}
        onSaveClick={onSave}
        disabledCancel={false}
        disabledSave={!isValid || !isDirty || isSubmitting}
        cancelText="Cancel"
        saveText="Save"
        buttonClassName="w-20"
        wrapperClassName="flex justify-end w-full"
        orientation="right"
        loading={false}
        withSubmit={true}
      />
    </div>
  )
}

export default LicenseFooter
