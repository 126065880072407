var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import { useId } from '../../hooks';
import AptiveIcon from '../Icon';
var Checkbox = React.forwardRef(function (props, ref) {
    var id = props.id, className = props.className, _a = props.checked, checked = _a === void 0 ? false : _a, onChange = props.onChange, children = props.children, _b = props.disabled, disabled = _b === void 0 ? false : _b, title = props.title, ariaLabel = props["aria-label"], _c = props.position, position = _c === void 0 ? "left" : _c, _d = props.isInValid, isInValid = _d === void 0 ? false : _d, other = __rest(props, ["id", "className", "checked", "onChange", "children", "disabled", "title", 'aria-label', "position", "isInValid"]);
    var checkbox = useContext(ThemeContext).theme.checkbox;
    var tempInputId = useId('input_');
    var layoutStyle = checkbox.content;
    var baseStyle = checkbox.base;
    var activeStyle = checkbox.active;
    var validStyle = checkbox.valid;
    var invalidStyle = checkbox.invalid;
    var disabledStyle = checkbox.disabled;
    function hasValidation(isInValid) {
        return isInValid !== undefined;
    }
    function validationStyle(inValid) {
        if (hasValidation(inValid)) {
            return !inValid ? (!disabled ? validStyle : disabledStyle) : invalidStyle;
        }
        return '';
    }
    var cls = classNames(layoutStyle, !hasValidation(isInValid) && !disabled && activeStyle, !hasValidation(isInValid) && disabled && disabledStyle, validationStyle(isInValid), className);
    var disabledCheckboxStyle = checkbox.icon.disabled;
    var invalidCheckboxStyle = checkbox.icon.invalid;
    var validCheckboxStyle = checked ? checkbox.icon.valid : checkbox.icon.uncheckedValid;
    var activeCheckboxStyle = checkbox.icon.active;
    var checkboxStyles = classNames(baseStyle, !isInValid ? (disabled ? disabledCheckboxStyle : classNames(validCheckboxStyle, activeCheckboxStyle)) : invalidCheckboxStyle);
    var handleCheckboxChange = function (event) {
        onChange === null || onChange === void 0 ? void 0 : onChange(event.target.checked, event);
    };
    var inputId = id ? "input_".concat(id) : tempInputId;
    var labelStyles = classNames(children && position === "left" ? checkbox.space.left : checkbox.space.right);
    return (_jsxs("label", __assign({ className: cls }, other, { children: [children && position === "right" ? _jsx("label", __assign({ className: labelStyles, "aria-label": "Checkbox Label", htmlFor: inputId }, { children: children })) : null, _jsx("input", { type: "checkbox", ref: ref, className: checkboxStyles, checked: checked, title: title, onChange: handleCheckboxChange, "aria-label": ariaLabel, id: inputId, disabled: disabled, hidden: true }), checked ?
                _jsx("label", __assign({ htmlFor: inputId }, { children: _jsx(AptiveIcon, { className: checkboxStyles, icon: "check", isFilled: true }) })) :
                _jsx("label", { className: checkboxStyles, htmlFor: inputId }), children && position === "left" ? _jsx("label", __assign({ className: labelStyles, "aria-label": "Checkbox Label", htmlFor: inputId }, { children: children })) : null] })));
});
export default Checkbox;
