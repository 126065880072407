import { Team } from 'interface'
import { apiClient } from './config'

export const fetchTeams = async () : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    teams: Team[]
  }
}> => {
  const response = await apiClient('teams', {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}
