import { Modal } from '@aptive-env/storybook'
import { FC } from 'react'
import LicenseApplicationForm from './LicenseApplicationForm'
import { ApplicationDetails, Attachment } from 'interface'

interface LicenseApplicationModalProps {
  isOpen: boolean
  details: Partial<ApplicationDetails>
  attachments: Attachment[]
  checklistAttachments: Attachment[]
  onClose: () => void
  onSubmit: ({ success, message }: { success: boolean, message: string }) => void
}

const LicenseApplicationModal: FC<LicenseApplicationModalProps> = ({
  isOpen,
  details,
  attachments,
  checklistAttachments,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal
      open={isOpen}
      onClickOutside={onClose}
    >
      <LicenseApplicationForm
        details={details}
        attachments={attachments}
        checklistAttachments={checklistAttachments}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  )
}

export default LicenseApplicationModal
