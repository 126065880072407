import { mergeClassName } from 'lib/utils'
import { useMemo, memo } from 'react'

const colorMap: { [key: string]: string } = {
  white: 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-blue-600',
  red: 'border-transparent bg-red-600 text-white focus:ring-red-600',
  active: 'border-transparent bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-600',
  disabled: 'text-gray-300 cursor-default',
  default: 'text-gray-600 bg-white font-normal sm:text-xs leading-none border-none shadow-none',
}

interface CustomButtonProps {
  onClick?: () => void
  color?: 'white' | 'red' | 'active' | 'disabled' | 'default' 
  className?: string
  disabled?: boolean
  name?: string
  type?: 'button' | 'submit'
  text?: string
  children?: React.ReactNode
}

const CustomButton = ({
  onClick,
  color = 'default',
  className,
  disabled,
  name,
  type = 'button',
  text,
  children,
}: CustomButtonProps) => {
  const buttonClasses = useMemo(() => mergeClassName(
    'inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm',
    colorMap[disabled ? 'disabled' : color],
    className,
  ), [color, disabled, className])

  return (
    <button
      name={name}
      type={type ? type : 'button'}
      disabled={disabled}
      className={mergeClassName(buttonClasses)}
      onClick={onClick}
    >
      {children ? children : text}
    </button>
  )
}

export default memo(CustomButton)
