import React, { useState, useCallback, useEffect, useMemo } from 'react'
import FilePreview from './FilePreview'
import CustomErrorMessage from '../CustomErrorMessage'
import DropZone from './DropZone'
import { Attachment } from 'interface'

interface CustomFileProps {
  id: string
  attachmentLabel?: string
  attachmentDescription?: string
  acceptedFileTypes: string[]
  maxFileSize: number
  maxFileCount: number
  defaultFiles?: (File | Attachment)[]
  onChange?: (payload: {
    target: {
      name: string
      value: (File | Attachment)[]
    }
  }) => void
  onRemoveFile?: (file: (File | Attachment)) => void
  onPreviewFile?: (file: (File | Attachment)) => void
  onDownloadFile?: (file: (File | Attachment)) => void
}

const CustomFile: React.FC<CustomFileProps> = ({
  id,
  attachmentLabel, 
  attachmentDescription, 
  acceptedFileTypes, 
  maxFileSize, 
  maxFileCount,
  defaultFiles,
  onChange,
  onPreviewFile,
  onDownloadFile
}) => {
  const [files, setFiles] = useState<(File | Attachment)[]>(defaultFiles ?? [])
  const [isDragging, setIsDragging] = useState(false)
  const [fileTypeErrorMessage, setFileTypeErrorMessage] = useState<boolean>(false)
  const [fileSizeErrorMessage, setFileSizeErrorMessage] = useState<boolean>(false)

  const remainingFileCount = useMemo<number>(() => maxFileCount - files.length, [files.length, maxFileCount])

  const handleDragEnter = useCallback((event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(true)
  }, [])

  const handleDragLeave = useCallback((event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(false)
  }, [])

  const handleDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(true)
  }, [])

  const handleDrop = useCallback((event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(false)
    setFileTypeErrorMessage(false)
    setFileSizeErrorMessage(false)

    if (event.dataTransfer.files) {
      const files = Array.from(event.dataTransfer.files).slice(0, remainingFileCount).filter(file => {
        if (!acceptedFileTypes.includes(file.type)) {
          setFileTypeErrorMessage(true)
          return false
        }
        if (file.size > maxFileSize) {
          setFileSizeErrorMessage(true)
          return false
        }
        return true
      })
      setFiles(prevFiles => [...prevFiles, ...files])
    }
  }, [acceptedFileTypes, maxFileSize, remainingFileCount])

  const handleFileUpload = (uploadedFiles: FileList) => {
    setFileTypeErrorMessage(false)
    setFileSizeErrorMessage(false)
    const files = Array.from(uploadedFiles).slice(0, remainingFileCount).filter(file => {
      if (!acceptedFileTypes.includes(file.type)) {
        setFileTypeErrorMessage(true)
        return false
      }
      if (file.size > maxFileSize) {
        setFileSizeErrorMessage(true)
        return false
      }
      return true
    })
    setFiles(prevFiles => [...prevFiles, ...files])
  }

  const handleRemoveFile = (file: File | Attachment) => {
    if ((file as Attachment).id) {
      setFiles(prevFiles => prevFiles.filter((prevFile) => (prevFile as Attachment).id !== (file as Attachment).id))
    } else {
      setFiles(prevFiles => prevFiles.filter((prevFile) => (prevFile as File).name !== (file as File).name))
    }
  }

  useEffect(() => {
    if (onChange) {
      onChange({
        target: {
          name: id,
          value: files
        }
      })
    }
  }, [files, id, onChange])

  return (
    <div>
      <div className='flex flex-col gap-4'>
        {attachmentLabel && <div className='text-base font-semibold leading-5'>{attachmentLabel}</div>}
        {files.length !== maxFileCount && (
          <>
            {attachmentDescription && <div className='text-sm text-gray-500'>{attachmentDescription}</div>}
            <DropZone
              isDragging={isDragging}
              handleDragEnter={handleDragEnter}
              handleDragLeave={handleDragLeave}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              handleFileUpload={handleFileUpload}
              acceptedFileTypes={acceptedFileTypes}
              maxFileSize={maxFileSize}
            />
            {fileTypeErrorMessage && (
              <CustomErrorMessage text='Unsupported file type. Please try again.' isicon={true} />
            )}
            {fileSizeErrorMessage && (
              <CustomErrorMessage text='Unsupported file size. Please try again.' isicon={true} />
            )}
          </>
        )}
      </div>
      {files.length > 0 && (
        <FilePreview
          files={files}
          onRemove={handleRemoveFile} 
          onPreview={onPreviewFile}
          onDownload={onDownloadFile} 
        />
      )}
    </div>
  )
}

export default CustomFile
