import { render } from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigCatProvider } from 'configcat-react'

const container = document.getElementById('root') as HTMLElement

const key = process.env.REACT_APP_CONFIGCAT_SDK_KEY ?? ''

render(
  <ConfigCatProvider sdkKey={key}>
    <App />
  </ConfigCatProvider>,
  container
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
