import Cookies from 'js-cookie'

const generateApiUrlByVersion = (version: number) => {
  const baseUrl = `${process.env.REACT_APP_LICENSING_API_URL}/licensing/v1` || 'http://localhost/api/v1'
  return baseUrl.replace(/\/v1[/]?/, `/v${version}`)
}

const baseURL = generateApiUrlByVersion(1)
const authToken = Cookies.get('tokenPayload') ?? process.env.REACT_APP_LICENSING_API_AUTH_TOKEN

export const apiClient = async (apiUrl: string, { method, headers, body, responseMode }: { method?: string, headers?: HeadersInit, body?: any, responseMode?: string }) => {
  const customHeader = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${authToken}`,
    ...headers
  }

  const customConfig = {
    method: method || 'GET',
    headers: customHeader
  }

  return fetch(`${baseURL}/${apiUrl}`, { ...customConfig, body })
    .then((response) => {
      if (response.status === 401) {
        window.location.reload()
        throw new Error('Unauthorized')
      }

      if (response.ok) {
        if (responseMode === 'blob')
          return response.blob()
        if (responseMode === 'text')
          return response.text()
        else
          return response.json()
      }
    })
}
