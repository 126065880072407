var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import AptiveIcon from '../Icon';
import { isNumber } from '../../helpers/common';
export var Badge = function (_a) {
    var id = _a.id, className = _a.className, children = _a.children, _b = _a.color, color = _b === void 0 ? "default" : _b, _c = _a.size, size = _c === void 0 ? "default" : _c, icon = _a.icon, isFilled = _a.isFilled, _d = _a.position, position = _d === void 0 ? "left" : _d, props = __rest(_a, ["id", "className", "children", "color", "size", "icon", "isFilled", "position"]);
    var badge = useContext(ThemeContext).theme.badge;
    var baseStyle = badge.base;
    var sizeBadgeStyles = !icon && isNumber(children) ? { small: badge.circle, default: badge.circle } : { small: badge.size.small, default: badge.size.default };
    var iconType = isFilled ? "filled" : "outlined";
    var colorStyles = {
        default: badge.color.default.content + " " + badge.color.default.icon[iconType],
        success: badge.color.success.content + " " + badge.color.success.icon[iconType],
        warning: badge.color.warning.content + " " + badge.color.warning.icon[iconType],
        error: badge.color.error.content + " " + badge.color.error.icon[iconType],
        gray: badge.color.gray.content + " " + badge.color.gray.icon[iconType],
    };
    var iconStyle = badge.icon[size].size;
    var iconLeftStyles = classNames(iconStyle, children ? badge.icon[size].space.left : '');
    var iconRightStyles = classNames(iconStyle, children ? badge.icon[size].space.right : '');
    var cls = classNames(baseStyle, sizeBadgeStyles[size], colorStyles[color], className);
    return (_jsxs("div", __assign({ id: id, className: cls }, props, { children: [icon && position === 'left' ?
                _jsx(AptiveIcon, { className: iconLeftStyles, icon: icon, isFilled: isFilled }) : null, children, icon && position === 'right' ?
                _jsx(AptiveIcon, { className: iconRightStyles, icon: icon, isFilled: isFilled }) : null] })));
};
