import { useCallback, useState, FC } from 'react'
import { AptiveIcon, Button } from '@aptive-env/storybook'
import debounce from 'lodash.debounce'

interface TableHeaderProps {
  search: string
  setSearch: (search: string) => void
  addLabel: string
  searchPlaceholder?: string
  addNewModalOpen: () => void
}

const TableHeader: FC<TableHeaderProps> = ({
  search,
  setSearch,
  addLabel,
  searchPlaceholder,
  addNewModalOpen,
}) => {
  const [searchValue, setSearchValue] = useState(search)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(debounce(setSearch, 500), [])

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    debouncedSearchHandler(event.target.value)
  }

  return (
    <div className="flex justify-between px-4 py-3 bg-white rounded-t-lg border border-gray-200">
      <div className="flex items-center bg-white border border-[#E5E7EB] rounded-md h-[38px] w-[240px] overflow-hidden">
        <input
          className="px-3 w-full focus-visible:outline-none text-[#3d3d3d] text-sm placeholder-[#A9A9A9] border-none focus:border-none focus:ring-0"
          value={searchValue}
          onChange={onInputChange}
          placeholder={searchPlaceholder ? searchPlaceholder : 'Search'}
        />
        <div className="px-3 border-l h-full flex items-center">
          <AptiveIcon className="w-[20px] h-[20px] stroke-[#9CA3AF] fill-none" icon="search" />
        </div>
      </div>
      <Button
        icon="plusCircle"
        size="default"
        variant="default"
        onClick={addNewModalOpen}
      >
        {addLabel}
      </Button>
    </div>
  )
}

export default TableHeader
