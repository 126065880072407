import { useState, useRef } from 'react'
import { Modal, Button, Toast } from '@aptive-env/storybook'

import { Application } from 'interface'
import { deleteApplication } from 'services'

interface IDeleteLicenseApplicationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onHideShow: (licenseId: number, isHidden: boolean) => void;
  application: Application | null;
  refresh: () => void;
}

const DeleteLicenseApplicationModal: React.FC<IDeleteLicenseApplicationModalProps> = ({ isOpen, onClose, onHideShow, application, refresh }) => {
  const [showDeleteToast, setShowDeleteToast] = useState(false)
  const [isDeleteCancelled, setIsDeleteCancelled] = useState(false)
  const isDeleteCancelledRef = useRef(isDeleteCancelled)
  isDeleteCancelledRef.current = isDeleteCancelled

  const handleDelete = async () => {
    setShowDeleteToast(true)
    onHideShow(application?.id || 0, true)
    
    setTimeout(() => {
      setShowDeleteToast(false)
      if(isDeleteCancelledRef.current) {
        setIsDeleteCancelled(false)
      } else {
        deleteApplication(application!.id)
          .then(() => {
            setIsDeleteCancelled(false)
            refresh()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }, 5000)

    onClose()
  }

  const handleUndoDelete = () => {
    setIsDeleteCancelled(true)
    setShowDeleteToast(false)
    onHideShow(application?.id || 0, false)
  }

  return (
    <>
      <Modal open={isOpen} onClickOutside={onClose} >
        <div className="flex flex-col w-[512px] p-6">
          <div className="text-lg leading-6 font-medium text-gray-900 mb-2">Delete License Application?</div>
          <div className="text-sm leading-5 text-gray-600 mb-4">
            Are you sure you want to delete this license application? This action cannot be undone.
          </div>
          <div className="flex justify-end gap-2">
            <Button variant="neutral" onClick={onClose}>
              Go back
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete License Application
            </Button>
          </div>
        </div>
      </Modal>
      <div className="fixed top-3 right-3 z-[100]">
        {
          showDeleteToast && (
            <Toast
              dismissible
              isIcon
              header="Success"
              isActionButton
              actionButtonTitle="Undo"
              onClickActionButton={handleUndoDelete}
              onDismiss={() => setShowDeleteToast(false)}
              status="success"
            >
              Well done, you successfully deleted a license application.
            </Toast>
          )          
        }
      </div>
    </>
  )
}

export default DeleteLicenseApplicationModal
