var defaultTheme = {
    // Pagination
    pagination: {
        base: 'flex flex-col justify-between text-xs sm:flex-row text-gray-600 dark:text-gray-400',
    },
    // Sidebar
    sidebar: {
        content: 'absolute h-screen py-[20px] flex flex-col border-r-[1px] transition-all duration-500 transform -translate-x-full',
        item: {
            base: 'w-[256px] min-w-[256px] cursor-pointer mb-[4px] text-sm font-normal leading-4',
            list: 'px-[40px] py-[8px] space-y-1 text-gray-600 group hover:text-blue-500 hover:bg-gray-50',
            link: 'block px-[40px] py-[8px] space-y-1 text-gray-600 group hover:text-blue-500 hover:bg-gray-50',
            title: 'flex items-center text-gray-600 group-hover:text-blue-500',
            leftIcon: 'mr-[8px] h-[20px] w-[20px] fill-gray-600 group-hover:fill-blue-500',
            activedItemTitle: 'pt-[13px] px-[24px] pb-[6px] text-base font-semibold leading-5',
            selected: 'border-l-[2px] border-gray-900 text-sm font-medium leading-4 bg-gray-100',
            itemTitle: 'mr-[8px]',
            childItem: 'pl-[69px]'
        }
    },
    // Navbars
    navbars: {
        content: 'navbars flex flex-wrap relative border-b-[1px] border-gray-200'
    },
    // Navbar
    navbar: {
        content: 'px-[16px] py-[14px] border-b-[2px] cursor-pointer text-sm font-normal leading-4 relative',
        children: 'absolute left-0 top-[calc(100%_+_2px)]',
        base: 'text-gray-600 border-white hover:text-gray-900',
        active: 'text-gray-900 border-gray-900',
        disabled: 'text-gray-400 border-white'
    },
    // Menu
    menu: {
        content: 'absolute py-[8px] flex flex-wrap flex-col shadow-[0px_3px_8px_0px_rgba(87,102,117,0.06),0px_6px_12px_0px_rgba(106,115,129,0.16)] left-0 z-50 rounded-b-lg',
        item: {
            base: 'w-[350px] min-w-[250px] cursor-pointer',
            list: 'px-[32px] py-[10px] space-y-1 text-gray-600 group hover:text-gray-900 hover:bg-gray-50',
            link: 'block px-[32px] py-[10px] space-y-1 text-gray-600 group hover:text-gray-900 hover:bg-gray-50',
            title: 'flex justify-between items-center text-base font-semibold leading-5 text-gray-900 group-hover:text-blue-500',
            contentText: 'text-sm font-normal leading-4',
            rightIcon: 'ml-[4px] h-[20px] w-[20px] fill-gray-600 group-hover:fill-blue-500',
            back: 'text-base font-semibold leading-5 text-gray-900 group',
            backTitle: 'flex items-center border-b-[1px] pt-[18px] px-[24px] pb-[12px] hover:text-blue-500',
            backIcon: 'mr-[8px] h-[20px] w-[20px] fill-gray-600 group-hover:fill-gray-900',
            activedItemTitle: 'pt-[13px] px-[24px] pb-[6px] text-base font-semibold leading-5'
        },
        recent: {
            content: 'px-[32px] py-[20px] space-y-[16px] border-t-[1px] bg-gray-50 w-[350px] min-w-[250px]',
            title: 'text-sm font-medium leading-4 uppercase',
            link: 'text-xs font-normal leading-4 text-blue-500',
        }
    },
    // Progress
    progress: {
        content: "w-full bg-gray-200 rounded-full mt-6",
        value: "bg-blue-500 rounded-full h-[8px]",
        label: "text-right text-xs font-semibold leading-4 text-gray-600 absolute right-0 top-0"
    },
    // Loading
    loading: {
        loadingContent: 'absolute animate-spin flex justify-center items-center w-[30px] h-[30px] bg-[conic-gradient(var(--tw-gradient-stops))] from-gray-600 to-transparent rounded-full',
        loadingInsideStyle: 'w-[20px] h-[20px] bg-white rounded-full',
        ball: {
            content: 'relative w-[30px] h-[30px] rounded-full animate-spin',
            span: 'absolute left-[12.5px] top-0 flex h-[5px] w-[5px]',
            base: 'bg-white-500 relative inline-flex h-[5px] w-[5px] rounded-full bg-gray-900'
        }
    },
    // Tooltip
    tooltip: {
        content: 'group relative inline-block text-white text-sm font-normal leading-4',
        open: 'opacity-100 delay-75',
        icon: 'w-[12px] h-[12px] mr-[8px] fill-green-400 stroke-none',
        position: {
            top: 'absolute bottom-full left-1/2 z-20 mb-2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-800 p-3 opacity-0 group-hover:opacity-100',
            bottom: 'absolute top-full left-1/2 z-20 mt-2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-800 p-3 opacity-0 group-hover:opacity-100',
            left: 'absolute right-full top-1/2 z-20 mr-2 -translate-y-1/2 whitespace-nowrap rounded bg-gray-800 p-3 opacity-0 group-hover:opacity-100',
            right: 'absolute left-full top-1/2 z-20 ml-2 -translate-y-1/2 whitespace-nowrap rounded bg-gray-800 p-3 opacity-0 group-hover:opacity-100'
        },
        direction: {
            top: 'absolute -bottom-1 -z-10 h-3 w-3 -translate-x-1/2 rotate-45 rounded-l-sm bg-gray-800',
            bottom: 'absolute -top-1 -z-10 h-3 w-3 -translate-x-1/2 rotate-45 rounded-sm bg-gray-800',
            left: 'absolute -right-1 -z-10 h-3 w-3 -translate-y-1/2 rotate-45 rounded-sm bg-gray-800',
            right: 'absolute -left-1 -z-10 h-3 w-3 -translate-y-1/2 rotate-45 rounded-r-sm bg-gray-800'
        },
        alignment: {
            start: {
                top: '',
                bottom: '',
                left: '',
                right: '',
            },
            center: {
                top: 'left-1/2',
                bottom: 'left-1/2',
                left: 'top-1/2',
                right: 'top-1/2',
            },
            end: {
                top: 'right-0',
                bottom: 'right-0',
                left: 'bottom-0',
                right: 'bottom-0',
            },
        }
    },
    // Tabs
    tabs: {
        content: 'tabs flex flex-wrap text-center'
    },
    // Tab
    tab: {
        content: 'cursor-pointer text-sm font-normal leading-4',
        base: 'text-gray-600 hover:text-gray-900 hover:border-gray-900',
        active: 'text-blue-500 border-blue-500',
        disabled: 'text-gray-400'
    },
    // Toast
    toast: {
        base: 'font-inter p-[15px] rounded-md group shadow-[0px_2px_8px_0px_rgba(106,115,129,0.12)] max-w-[500px]',
        noHeaderStyle: 'text-sm font-normal leading-4leading-5',
        withHeaderStyle: 'text-sm font-semibold leading-4 leading-6 h-[24px]',
        avatarHeaderStyle: 'mb-[5px] text-sm font-semibold leading-4 text-gray-900',
        closeBtnStyle: 'ml-auto rounded-lg inline-flex',
        childrenBodyStyle: 'mt-[6px] text-sm font-normal leading-4',
        actionButtonStyle: 'mt-[12px]',
        withAvatar: "bg-white text-gray-600 border border-transparent hover:bg-gray-50",
        success: 'bg-white text-gray-600 border border-gray-200',
        danger: 'bg-white text-gray-600 border border-gray-200',
        warning: 'bg-white text-gray-600 border border-gray-200',
        info: 'bg-white text-gray-600 border border-gray-200',
        header: {
            success: 'text-green-600 group-hover:text-green-700',
            danger: 'text-red-700 group-hover:text-red-800',
            warning: 'text-yellow-700 group-hover:text-yellow-900',
            info: 'text-blue-500 group-hover:text-[#0066D5]',
        },
        icon: {
            base: 'flex-shrink-0 h-[32px] w-[32px] p-[6px] rounded-md',
            closeWithAvatar: 'flex-shrink-0 h-[20px] w-[20px] rounded-md ml-[16px] fill-gray-400',
            header: 'flex-shrink-0 h-[18px] w-[18px] rounded-md',
            color: {
                success: 'fill-green-600 group-hover:fill-green-700',
                danger: 'fill-red-700 group-hover:fill-red-800',
                warning: 'fill-yellow-700 group-hover:fill-yellow-900',
                info: 'fill-blue-500 group-hover:fill-[#0066D5]',
            },
            bg: {
                success: 'bg-gray-50',
                danger: 'bg-gray-50',
                warning: 'bg-gray-50',
                info: 'bg-gray-50',
            }
        }
    },
    // Chip
    chip: {
        base: "font-inter items-center inline-flex justify-center rounded-full align-middle",
        circle: "min-w-[20px] p-[2px] text-xs font-normal leading-4",
        size: {
            default: 'px-[8px] py-[3px] text-xs font-normal leading-4',
        },
        icon: {
            default: {
                size: 'h-[12px] w-[12px]',
                space: {
                    left: 'mr-[4px]',
                    right: 'ml-[4px]'
                }
            }
        },
        color: {
            default: {
                content: "bg-white text-gray-600 border border-gray-200 hover:bg-gray-100 hover:border-gray-400",
                icon: {
                    filled: "fill-gray-600 stroke-none",
                    outlined: "stroke-gray-600 fill-none",
                }
            },
            active: {
                content: "bg-blue-50 text-blue-500 border border-transparent hover:bg-blue-100 hover:text-[#0066D5]",
                icon: {
                    filled: "fill-blue-500 stroke-none hover:fill-[#0066D5]",
                    outlined: "stroke-blue-500 fill-none hover:stroke-[#0066D5]",
                }
            },
            disabled: {
                content: "bg-white text-gray-400 border border-gray-400 hover:border-gray-500 hover:text-gray-500",
                icon: {
                    filled: "fill-gray-400 stroke-none hover:fill-gray-500",
                    outlined: "stroke-gray-400 fill-none hover:stroke-gray-500",
                }
            }
        }
    },
    // Breadcrumb
    breadcrumb: {
        content: "inline-flex items-center",
        base: "text-gray-600 hover:text-gray-700",
        current: "text-blue-500 hover:text-blue-600",
        slash: "mx-2 text-gray-400 px-[9px]"
    },
    // Dropdown
    dropdownItem: {
        base: 'mb-2 last:mb-0',
    },
    dropdown: {
        base: 'absolute mt-2 py-1 min-w-[200px] origin-top-right divide-y divide-gray-100 rounded-md bg-white border border-[rgba(255, 255, 255, 1.0)] shadow-[0px_3px_8px_0px_rgba(87,102,117,0.06),0px_6px_12px_0px_rgba(106,115,129,0.16)] focus:outline-none',
        content: "relative min-h-[350px]",
        checkboxContent: "min-h-[36px] flex text-start w-full",
        item: {
            content: "group min-h-[36px] flex w-full items-center px-3 py-2 text-sm",
            base: "text-gray-600",
            active: "hover:bg-gray-100 hover:text-gray-900 focus:text-gray-900",
            selected: "text-gray-900, bg-gray-50",
            icon: {
                space: {
                    right: 'absolute right-3',
                },
                base: {
                    filled: "fill-gray-600 stroke-none",
                    outlined: "stroke-gray-600 fill-none",
                },
                active: {
                    filled: "hover:fill-gray-900 hover:stroke-none",
                    outlined: "hover:stroke-gray-900 hover:fill-none",
                },
                selected: {
                    filled: "fill-gray-900 stroke-none",
                    outlined: "stroke-gray-900 fill-none",
                }
            }
        },
        size: {
            default: 'px-[12px] py-[8px] rounded-lg bg-white text-sm font-medium leading-4',
        },
        transition: {
            enter: "transition ease-out duration-100",
            enterFrom: "transform opacity-0 scale-95",
            enterTo: "transform opacity-100 scale-100",
            leave: "transition ease-in duration-75",
            leaveFrom: "transform opacity-100 scale-100",
            leaveTo: "transform opacity-0 scale-95"
        }
    },
    // Shadow
    shadow: {
        content: "flex flex-row items-center justify-between space-x-[10px] flex-wrap",
        colum: "font-inter flex flex-col text-center",
        base: "min-w-[200px] min-h-[200px] border border-[rgba(255, 255, 255, 1.0)]",
        high: "shadow-[0px_12px_20px_0px_rgba(106,115,129,0.22)]",
        med: "shadow-[0px_3px_8px_0px_rgba(87,102,117,0.06),0px_6px_12px_0px_rgba(106,115,129,0.16)]",
        low: "shadow-[0px_2px_8px_0px_rgba(106,115,129,0.12)]",
        top: "shadow-[0px_-2px_8px_0px_rgba(106,115,129,0.12)]",
        title: "text-sm font-medium leading-4 leading-8",
        code: "text-sm font-normal leading-4",
    },
    // Switch
    toggle: {
        content: "font-inter relative inline-flex items-center cursor-pointer text-sm font-normal leading-4",
        vaild: "text-gray-600",
        active: "",
        disabled: "text-gray-400",
        space: {
            left: "ml-[8px]",
            right: "mr-[8px]"
        },
        input: "sr-only peer",
        icon: {
            base: "w-[32px] h-[16px] border rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-0 after:ml-[-0.75px] after:border after:rounded-full after:h-[16px] after:w-[16px] after:transition-all ",
            default: "border-gray-500 bg-gray-100 after:bg-white after:border-gray-500 peer-checked:bg-blue-500",
            disabled: "border-gray-400 bg-gray-200 after:bg-white after:border-gray-400",
            active: ""
        }
    },
    // Radio
    radio: {
        content: "font-inter inline-flex items-center justify-center text-sm font-normal leading-4",
        base: "w-[16px] h-[16px]",
        vaild: "text-gray-600",
        active: "",
        disabled: "text-gray-400",
        space: {
            left: "ml-[8px]",
            right: "mr-[8px]"
        },
    },
    // Checkbox
    checkbox: {
        content: "font-inter inline-flex items-center justify-center text-sm font-normal leading-4 flex",
        base: "w-[16px] h-[16px]",
        active: "",
        valid: "text-gray-600",
        invalid: "text-red-500",
        disabled: "text-gray-400",
        space: {
            left: "ml-[8px] flex-auto",
            right: "mr-[8px] flex-auto"
        },
        icon: {
            disabled: "fill-gray-400 stroke-none box-border border border-gray-400 rounded-sm",
            invalid: "fill-red-500 stroke-none box-border border border-red-500 rounded-sm",
            valid: "bg-blue-500 fill-white stroke-none box-border border border-blue-500 rounded-sm",
            uncheckedValid: "box-border border border-gray-600 rounded-sm",
            active: ""
        }
    },
    // Alert
    alert: {
        base: 'font-inter p-[16px] rounded-md group max-w-[600px]',
        noHeaderStyle: 'text-sm font-normal leading-4',
        withHeaderStyle: 'text-sm font-semibold leading-4 leading-6 h-[24px]',
        closeBtnStyle: 'ml-auto rounded-lg inline-flex',
        childrenBodyStyle: 'mt-[6px] text-sm font-normal leading-4',
        actionButtonStyle: 'mt-[12px]',
        success: 'bg-green-100 text-green-600 hover:text-green-700',
        danger: 'bg-red-100 text-red-700 hover:text-red-800',
        warning: 'bg-yellow-100 text-yellow-700 hover:text-yellow-900',
        dark: 'bg-gray-100 text-gray-600 hover:text-gray-700',
        info: 'bg-blue-100 text-blue-500 hover:text-[#0066D5]',
        icon: {
            base: 'flex-shrink-0 h-[20px] w-[20px]',
            success: 'fill-green-600 group-hover:fill-green-700',
            danger: 'fill-red-700 group-hover:fill-red-800',
            warning: 'fill-yellow-700 group-hover:fill-yellow-900',
            dark: 'fill-gray-600 group-hover:fill-gray-700',
            info: 'fill-blue-500 group-hover:fill-[#0066D5]',
        }
    },
    // Badge
    badge: {
        base: "font-inter items-center inline-flex justify-center rounded-md",
        circle: "min-w-[20px] p-[2px] text-sm font-medium leading-4",
        size: {
            default: 'px-[12px] py-[2px] text-sm font-medium leading-4',
            small: 'px-[10px] py-[2px] text-xs font-semibold leading-4',
        },
        icon: {
            default: {
                size: 'h-[16px] w-[16px] py-[2px]',
                space: {
                    left: 'mr-[4px]',
                    right: 'ml-[4px]'
                }
            },
            small: {
                size: 'h-[14px] w-[14px] py-[2px]',
                space: {
                    left: 'mr-[4px]',
                    right: 'ml-[4px]'
                }
            }
        },
        color: {
            default: {
                content: "bg-blue-100 text-blue-500 ",
                icon: {
                    filled: "fill-blue-500 stroke-none",
                    outlined: "stroke-blue-500 fill-none",
                }
            },
            success: {
                content: "bg-green-100 text-green-600 ",
                icon: {
                    filled: "fill-green-600 stroke-none",
                    outlined: "stroke-green-600 fill-none",
                }
            },
            warning: {
                content: "bg-yellow-100 text-yellow-700 ",
                icon: {
                    filled: "fill-yellow-700 stroke-none",
                    outlined: "stroke-yellow-700 fill-none",
                }
            },
            error: {
                content: "bg-red-200 text-red-700 ",
                icon: {
                    filled: "fill-red-700 stroke-none",
                    outlined: "stroke-red-700 fill-none",
                }
            },
            gray: {
                content: "bg-gray-100 text-gray-900 ",
                icon: {
                    filled: "fill-gray-900 stroke-none",
                    outlined: "stroke-gray-900 fill-none",
                }
            }
        }
    },
    // Avatar
    avatar: {
        base: 'font-inter relative rounded-full inline-block bg-cover bg-center cursor-pointer',
        size: {
            "small": 'w-[16px] h-[16px]',
            "default": 'w-[24px] h-[24px]',
            "medium": 'w-[32px] h-[32px]',
            "large": 'w-[40px] h-[40px]',
            "xl": 'w-[48px] h-[48px]',
            "2xl": 'w-[56px] h-[56px]',
            "3xl": 'w-[64px] h-[64px]',
        },
    },
    // Button
    button: {
        base: {
            btn: 'font-inter align-bottom inline-flex items-center justify-center transition-colors',
            icon: ''
        },
        block: 'w-full',
        font: {
            default: 'text-sm font-medium leading-4',
            small: 'text-xs font-semibold leading-4',
        },
        space: {
            default: 'px-[20px] py-[10px] rounded-lg',
            small: 'px-[12px] py-[8px] rounded-lg',
        },
        icon: {
            default: {
                size: 'h-[20px] w-[20px]',
                space: {
                    left: 'mr-[4px]',
                    right: 'ml-[4px]'
                }
            },
            small: {
                size: 'h-[12px] w-[12px]',
                space: {
                    left: 'mr-[6px]',
                    right: 'ml-[6px]'
                }
            }
        },
        default: {
            btn: {
                base: 'text-white border-transparent border bg-blue-500 focus:outline-none',
                active: 'active:bg-[#0066D5] hover:bg-[#0066D5]',
                disabled: 'bg-gray-50 border-transparent border text-gray-400 cursor-not-allowed'
            },
            icon: {
                filled: {
                    base: 'fill-white stroke-none',
                    active: '',
                    disabled: 'fill-gray-400 stroke-none'
                },
                outlined: {
                    base: 'stroke-white fill-none',
                    active: '',
                    disabled: 'stroke-gray-400 fill-none'
                }
            }
        },
        neutral: {
            btn: {
                base: 'text-gray-600 border-gray-200 border focus:outline-none',
                active: 'active:border-gray-400 hover:border-gray-400 active:text-gray-900 hover:text-gray-900',
                disabled: 'bg-gray-50 border-transparent border text-gray-400 cursor-not-allowed'
            },
            icon: {
                filled: {
                    base: 'fill-gray-600 stroke-none',
                    active: 'active:fill-gray-900 hover:fill-gray-900 active:stroke-none hover:stroke-none',
                    disabled: 'fill-gray-400 stroke-none'
                },
                outlined: {
                    base: 'stroke-gray-600 fill-none',
                    active: 'active:stroke-gray-900 hover:stroke-gray-900 active:fill-none hover:fill-none',
                    disabled: 'stroke-gray-400 fill-none'
                }
            }
        },
        transparent: {
            btn: {
                base: 'text-gray-600 border-transparent border focus:outline-none',
                active: 'active:bg-gray-200 hover:bg-gray-200 active:text-gray-900 hover:text-gray-900',
                disabled: 'bg-gray-50 border-transparent border text-gray-400 cursor-not-allowed'
            },
            icon: {
                filled: {
                    base: 'fill-gray-600 stroke-none',
                    active: 'active:fill-gray-900 hover:fill-gray-900 active:stroke-none hover:stroke-none',
                    disabled: 'fill-gray-400 stroke-none'
                },
                outlined: {
                    base: 'stroke-gray-600 fill-none',
                    active: 'active:stroke-gray-900 hover:stroke-gray-900 active:fill-none hover:fill-none',
                    disabled: 'stroke-gray-400 fill-none'
                }
            }
        },
        destructive: {
            btn: {
                base: 'text-white border-transparent border bg-[#E74C3C] focus:outline-none',
                active: 'active:bg-[#B72617] hover:bg-[#B72617]',
                disabled: 'bg-gray-50 border-transparent border text-gray-400 cursor-not-allowed'
            },
            icon: {
                filled: {
                    base: 'fill-white stroke-none',
                    active: '',
                    disabled: 'fill-gray-400 stroke-none'
                },
                outlined: {
                    base: 'stroke-white fill-none',
                    active: '',
                    disabled: 'stroke-gray-400 fill-none'
                }
            }
        },
        success: {
            btn: {
                base: 'text-white border-transparent border bg-green-600 focus:outline-none',
                active: 'active:bg-green-700 hover:bg-green-700',
                disabled: 'bg-gray-50 border-transparent border text-gray-400 cursor-not-allowed'
            },
            icon: {
                filled: {
                    base: 'fill-white stroke-none',
                    active: '',
                    disabled: 'fill-gray-400 stroke-none'
                },
                outlined: {
                    base: 'stroke-white fill-none',
                    active: '',
                    disabled: 'stroke-gray-400 fill-none'
                }
            }
        },
        warning: {
            btn: {
                base: 'text-yellow-700 border-transparent border bg-yellow-300 focus:outline-none',
                active: 'active:text-yellow-900 active:bg-yellow-400 hover:text-yellow-900 hover:bg-yellow-400',
                disabled: 'bg-gray-50 border-transparent border text-gray-400 cursor-not-allowed'
            },
            icon: {
                filled: {
                    base: 'fill-yellow-700 stroke-none',
                    active: 'fill-yellow-900 stroke-none',
                    disabled: 'fill-gray-400 stroke-none'
                },
                outlined: {
                    base: 'stroke-yellow-700 fill-none',
                    active: 'stroke-yellow-900 fill-none',
                    disabled: 'stroke-gray-400 fill-none'
                }
            }
        },
        info: {
            btn: {
                base: 'text-white border-transparent border bg-blue-700 focus:outline-none',
                active: 'active:bg-blue-800 hover:bg-blue-800',
                disabled: 'bg-gray-50 border-transparent border text-gray-400 cursor-not-allowed'
            },
            icon: {
                filled: {
                    base: 'fill-white stroke-none',
                    active: '',
                    disabled: 'fill-gray-400 stroke-none'
                },
                outlined: {
                    base: 'stroke-white fill-none',
                    active: '',
                    disabled: 'stroke-gray-400 fill-none'
                }
            }
        },
        dark: {
            btn: {
                base: 'bg-white border-transparent border text-gray-600 border-gray-200 border focus:outline-none',
                active: 'active:bg-gray-50 hover:bg-gray-50',
                disabled: 'bg-gray-50 border-transparent border text-gray-400 cursor-not-allowed'
            },
            icon: {
                filled: {
                    base: 'fill-gray-600 stroke-none',
                    active: '',
                    disabled: 'fill-gray-400 stroke-none'
                },
                outlined: {
                    base: 'stroke-gray-600 fill-none',
                    active: '',
                    disabled: 'stroke-gray-400 fill-none'
                }
            }
        },
        danger: {
            btn: {
                base: 'text-white border-transparent border bg-red-700 focus:outline-none',
                active: 'active:bg-red-800 hover:bg-red-800',
                disabled: 'bg-gray-50 border-transparent border text-gray-400 cursor-not-allowed'
            },
            icon: {
                filled: {
                    base: 'fill-white stroke-none',
                    active: '',
                    disabled: 'fill-gray-400 stroke-none'
                },
                outlined: {
                    base: 'stroke-white fill-none',
                    active: '',
                    disabled: 'stroke-gray-400 fill-none'
                }
            }
        },
        custom: {
            btn: {
                base: 'focus:outline-none',
                active: '',
                disabled: 'bg-gray-50 border-transparent border text-gray-400 cursor-not-allowed'
            },
            icon: {
                filled: {
                    base: 'fill-white stroke-none',
                    active: '',
                    disabled: 'fill-gray-400 stroke-none'
                },
                outlined: {
                    base: 'stroke-white fill-none',
                    active: '',
                    disabled: 'stroke-gray-400 fill-none'
                }
            }
        }
    }
};
export default defaultTheme;
