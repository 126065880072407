import React, { MouseEventHandler, useMemo } from 'react'
import { mergeClassName } from 'lib/utils'
import { AptiveIcon } from '@aptive-env/storybook'

interface BaseClasses {
  errorClasses?: string;
  standardClasses?: string;
}

interface ICustomInputProps {
  id: string;
  name: string;
  type: string;
  disabled: boolean;
  formValue: string;
  value: string | number | readonly string[] | undefined;
  hasError: boolean;
  max?: number;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>; 
  register?: (name: string) => { [key: string]: any};
  childOrientation?: 'left' | 'right';
  ariaDescribedBy?: string;
  placeholder?: string;
  autoComplete?: string;
  baseClasses?: BaseClasses;
  children?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  required?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  mark?: string;
  hasCopyButton?: boolean;
}

const CustomInput: React.FC<ICustomInputProps> = ({
  id,
  name,
  type,
  disabled,
  formValue,
  value,
  hasError,
  max,
  className,
  onChange,
  register,
  childOrientation,
  ariaDescribedBy,
  placeholder,
  autoComplete,
  baseClasses,
  children,
  onBlur,
  required,
  onKeyDown,
  mark,
  hasCopyButton,
}) => {
  const classes = useMemo(() => {
    const base =
      type !== 'checkbox'
        ? baseClasses?.[hasError ? 'errorClasses' : 'standardClasses'] || ''
        : ''

    return mergeClassName(
      'shadow-sm block w-full sm:text-sm rounded-md',
      base,
      className,
      { 'text-gray-400': disabled },
    )
  }, [type, baseClasses, hasError, className, disabled])

  const handleCopy: MouseEventHandler<HTMLOrSVGElement> = (event) => {
    event.stopPropagation()
    const input = document.getElementById(id)
    if (input) {
      (input as HTMLInputElement).select()
      document.execCommand('copy')
    }
  }

  return (
    <div className={`flex ${mark || hasCopyButton ? 'relative' : ''}`}>
      {childOrientation === 'left' ? children : null}
      {mark && <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-xs">{mark}</span>}
      <input
        {...(register && { ...register(name) })}
        id={id}
        max={max}
        name={name}
        type={type}
        value={register ? formValue : value}
        className={classes}
        {...(onChange && { onChange })}
        disabled={disabled}
        placeholder={placeholder}
        aria-describedby={ariaDescribedBy}
        aria-invalid={hasError}
        autoComplete={autoComplete}
        {...(onBlur && { onBlur })}
        required={required || false}
        onKeyDown={onKeyDown}
        {...(!register && type === 'checkbox' && { checked: !!value })}
      />
      {hasCopyButton && (
        <div className="absolute right-3 top-2 bg-white">
          <AptiveIcon
            className="w-[20px] h-[20px] stroke-[#9CA3AF] fill-none hover-gray-700 cursor-pointer hover:opacity-[0.7]"
            icon="documentDuplicate"
            onClick={handleCopy}
          />
        </div>
      )}
      {childOrientation === 'right' ? children : null}
    </div>
  )
}

export default CustomInput
