var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import AptiveIcon from '../Icon';
var Button = React.forwardRef(function Button(props, ref) {
    var id = props.id, className = props.className, _a = props.type, type = _a === void 0 ? 'button' : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b, _c = props.size, size = _c === void 0 ? 'default' : _c, _d = props.variant, variant = _d === void 0 ? 'default' : _d, _e = props.block, block = _e === void 0 ? false : _e, children = props.children, icon = props.icon, _f = props.isFilled, isFilled = _f === void 0 ? false : _f, _g = props.position, position = _g === void 0 ? 'left' : _g, other = __rest(props, ["id", "className", "type", "disabled", "size", "variant", "block", "children", "icon", "isFilled", "position"]);
    var button = useContext(ThemeContext).theme.button;
    var baseStyle = button.base.btn;
    var blockStyle = button.block;
    var fontStyles = {
        default: button.font.default,
        small: button.font.small
    };
    var spaceStyles = {
        default: !icon ? button.space.default : button.space.small,
        small: button.space.small
    };
    var iconType = isFilled ? "filled" : "outlined";
    var variantStyles = {
        default: classNames(button.default.btn.base, button.default.icon[iconType].base),
        neutral: classNames(button.neutral.btn.base, button.neutral.icon[iconType].base),
        transparent: classNames(button.transparent.btn.base, button.transparent.icon[iconType].base),
        destructive: classNames(button.destructive.btn.base, button.destructive.icon[iconType].base),
        success: classNames(button.success.btn.base, button.success.icon[iconType].base),
        warning: classNames(button.warning.btn.base, button.warning.icon[iconType].base),
        info: classNames(button.info.btn.base, button.info.icon[iconType].base),
        dark: classNames(button.dark.btn.base, button.dark.icon[iconType].base),
        danger: classNames(button.danger.btn.base, button.danger.icon[iconType].base),
        custom: classNames(button.custom.btn.base, button.custom.icon[iconType].base),
    };
    var activeStyles = {
        default: classNames(button.default.btn.active, button.default.icon[iconType].active),
        neutral: classNames(button.neutral.btn.active, button.neutral.icon[iconType].active),
        transparent: classNames(button.transparent.btn.active, button.transparent.icon[iconType].active),
        destructive: classNames(button.destructive.btn.active, button.destructive.icon[iconType].active),
        success: classNames(button.success.btn.active, button.success.icon[iconType].active),
        warning: classNames(button.warning.btn.active, button.warning.icon[iconType].active),
        info: classNames(button.info.btn.active, button.info.icon[iconType].active),
        dark: classNames(button.dark.btn.active, button.dark.icon[iconType].active),
        danger: classNames(button.danger.btn.active, button.danger.icon[iconType].active),
        custom: classNames(button.custom.btn.active, button.custom.icon[iconType].active),
    };
    var disabledStyles = {
        default: classNames(button.default.btn.disabled, button.default.icon[iconType].disabled),
        neutral: classNames(button.neutral.btn.disabled, button.neutral.icon[iconType].disabled),
        transparent: classNames(button.transparent.btn.disabled, button.transparent.icon[iconType].disabled),
        destructive: classNames(button.destructive.btn.disabled, button.destructive.icon[iconType].disabled),
        success: classNames(button.success.btn.disabled, button.success.icon[iconType].disabled),
        warning: classNames(button.warning.btn.disabled, button.warning.icon[iconType].disabled),
        info: classNames(button.info.btn.disabled, button.info.icon[iconType].disabled),
        dark: classNames(button.dark.btn.disabled, button.dark.icon[iconType].disabled),
        danger: classNames(button.danger.btn.disabled, button.danger.icon[iconType].disabled),
        custom: classNames(button.custom.btn.disabled, button.custom.icon[iconType].disabled),
    };
    var iconStyle = button.icon[size].size;
    var iconLeftStyles = classNames(iconStyle, children ? button.icon[size].space.left : '');
    var iconRightStyles = classNames(iconStyle, children ? button.icon[size].space.right : '');
    var buttonStyles = classNames(baseStyle, fontStyles[size], spaceStyles[size], disabled ? disabledStyles[variant] : classNames(variantStyles[variant], activeStyles[variant]), block ? blockStyle : null, className);
    return (_jsxs("button", __assign({ id: id, className: buttonStyles, type: type, disabled: disabled }, other, { ref: ref }, { children: [icon && position === 'left' ?
                _jsx(AptiveIcon, { className: iconLeftStyles, icon: icon, isFilled: isFilled }) : null, children, icon && position === 'right' ?
                _jsx(AptiveIcon, { className: iconRightStyles, icon: icon, isFilled: isFilled }) : null] })));
});
export default Button;
