import moment from 'moment'

export const formatDate = {
  FORMAT_TIMESTAMP: 'YYYY-MM-DD HH:mm:ss',
  FORMAT_REQUEST: 'YYYY-MM-DD',
  FORMAT_DISPLAY: 'MM-DD-YYYY',
  FORMAT_NOVEL: 'DD MMM YYYY',
  FORMAT_YEAR: 'YYYY',
}

export const momentDate = (date: string | Date) => (
  moment(date, [
    formatDate.FORMAT_TIMESTAMP,
    formatDate.FORMAT_REQUEST,
    formatDate.FORMAT_DISPLAY,
    formatDate.FORMAT_NOVEL,
    formatDate.FORMAT_YEAR,
  ])
)

export const formatDateFn: (date: Date, format?: string) => string = (date, format = formatDate.FORMAT_REQUEST) => {
  const result = momentDate(date).format(format)

  return result !== 'Invalid date' ? result : ''
}

export const formatDateDisplay = (date: Date, format = formatDate.FORMAT_DISPLAY) => (
  formatDateFn(date, format)
)
